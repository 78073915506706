.metamall-btn {
  &-primary {
    height: 30px;
    background-color: rgba(236, 156, 227, 0.18);
    border-radius: 9px;
    -webkit-box-shadow: rgba(196, 70, 183, 0.64);
    box-shadow: rgba(196, 70, 183, 0.64);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(9.83px);
    font-family: "Neue Machina bold";
    font-size: 12px;
    font-weight: 800;
    color: white;
    text-transform: uppercase;
    border: 0.9px solid rgba(236, 156, 227, 0.18);
    transition: background 0.3s;

    &:hover {
      background-color: transparent;
      border-color: rgba(236, 156, 227, 0.18);
    }
  }

  &-secondary {
    height: 30px;
    background-color: rgba(236, 156, 227, 0.09);
    border-radius: 9px;
    -webkit-box-shadow: 0 0 8px 0 rgb(196 70 183 / 64%);
    box-shadow: 0 0 8px 0 rgb(196 70 183 / 64%);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(9.83px);
    font-family: "Neue Machina bold";
    padding: 12px 14px;
    font-size: 12px;
    font-weight: 800;
    color: white;
    text-transform: uppercase;
    border: 0.9px solid rgba(255, 255, 255, 0.14);
    transition: background 0.3s;

    &:hover {
      background-color: transparent;
      border-color: rgba(236, 156, 227, 0.18);
    }
  }

  &-disabled {
    background-color: grey !important;
    cursor: not-allowed !important;
  }
}