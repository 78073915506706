.vertical-lines-loader {
  z-index: 25;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-line {
    width: 2px;
    height: 24px;
    background: #fff;
    margin: 0 6px;
    animation: loader-line 1000ms infinite ease-in-out;
  }

  .line-1 {
    animation-delay: 800ms;
  }

  .line-2 {
    animation-delay: 600ms;
  }

  .line-3 {
    animation-delay: 400ms;
  }

  .line-4 {
    animation-delay: 200ms;
  }

  .line-6 {
    animation-delay: 200ms;
  }

  .line-7 {
    animation-delay: 400ms;
  }

  .line-8 {
    animation-delay: 600ms;
  }

  .line-9 {
    animation-delay: 800ms;
  }
}

@keyframes loader-line {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}