.insufficient-funds-modal {
  .modal-content {
    width: 100%;
  }

  .modal-body {
    padding: 0;
  }

  &-dismiss-btn {
    height: 56px;
    width: 100%;
    font-family: NeueB;
    font-size: 16px;
  }

  &-header {
    padding: 28px 24px 28px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background-color: transparent;
      outline: none;
      border: none;
    }

    p {
      margin: 0;
      font-family: NeueB;
      color: white;
      font-weight: 600;
      font-size: 20px;
    }

    border-bottom: 0.01em solid rgba(255, 255, 255, 0.14);
  }

  &-content {
    padding: 24px;

    &-label {
      font-family: NeueB;
      font-weight: 800 ;
      letter-spacing: 1px;
      margin-bottom: 12px;
    }

    &-sub-label {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 28px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 5px;
    }

    &-section {
      padding: 20px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    &-price {
      font-family: NeueB;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 24px;
      font-weight: 800;
      img {
        width: 24px;
      }
    }

    &-info {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}