.full-screen-loading {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #0d101ee6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-btn {
    margin-top: 20px;
    height: 40px;
    padding: 12px 14px;
  }
}

.spinner-text {
  margin-top: 32px;
  font-family: 'Neue Machina bold';
  color: white;
  font-size: 20px;
}

.spinner-sub {
  margin-top: 8px;
  color: #9F9DA7;
  ;
  font-size: 18px;
  width: 50%;
  text-align: center
}