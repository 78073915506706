$white: #fff;
$border: rgba(255, 255, 255, 0.18);

.header-title {
  font-size: 18px;
  font-weight: 400;
  color: $white;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 50%;
  .bold {
    font-weight: 800;
    font-family: "Neue Machina bold";
  }
  .regular {
    font-weight: 400;
    font-family: "Neue Machina regular";
  }
  .header-icon {
    width: 20px;
    height: 20px;
    color: #c446b7;
    margin-right: 16px;
  }
  .buy-button {
    float: right;
    font-size: 12px;
    background: rgba(236, 156, 227, 0.17) !important;
    border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
    box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.3) !important;
    backdrop-filter: blur(9.83px) !important;
    border-radius: 8.13px !important;
    font-family: "Neue Machina bold";
    height: 40px;
    margin-right: -1%;
  }
}

.tag {
  padding: 6px;
  background: rgba(203, 255, 198, 0.15);
  border-radius: 6px;
  font-family: "Neue Machina bold";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #a9ffa1;
}

.pending-tag {
  padding: 8px;
  background: rgba(255, 162, 109, 0.15);
  border-radius: 6px;
  font-family: "Neue Machina bold";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #ffa26d;
  padding-right: 5px;
}

#line {
  width: 100%;
  border-top: 0.01em solid $border;
}
.bootstrap-loader {
  color: #de72c6;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-found-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  .text {
    color: $white;
    font-family: "Neue Machina bold";
    color: #413850;
    font-size: 1.5rem;
  }
  .error-component-wrapper {
    .error-image {
      width: 24px;
      margin-left: 8px;
      margin-bottom: 16px;
    }
  }
}

.alert-model {
  .modal-content {
    background-color: #17132a !important;
    color: white;
    font-family: "Neue Machina regular";
    padding: 32px;
    border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
    border-radius: 12px;
    .modal-header {
      background: rgba(255, 255, 255, 0.06) !important;
      font-family: "Neue Machina bold";
      border-bottom: 0px;
    }
  }
  .alert-message-wrapper {
    display: flex;
    height: 410px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .header-text {
      font-family: "Neue Machina bold";
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 17px;
      letter-spacing: 0.02em;
      color: white;
      margin-top: 36px;
    }
    .sub-text {
      font-family: "Neue Machina regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
      margin-top: 16px;
      /* or 181% */

      letter-spacing: 0.02em;

      color: #9f9da7;
    }
    .proceed-button {
      margin-top: 36px;
      height: 60px;
      width: 180px;
    }
  }
}

.opps-mobile-screen {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .header-image-wrapper {
    display: flex;
    justify-content: center;
  }
  .body-wrapper {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    .oops-image {
      width: 50%;
      height: 50%;
    }
    .oops-text {
      font-family: "Neue Machina reguler";
      font-size: 12px;
      padding: 0% 12% 0% 12%;
      color: white;
    }
  }
}
.btnConnect {
  display: flex;
  align-items: center;
  .header-wallet-wrapper {
    display: flex;
    border: 0;
    padding: 15px 0;
    button {
      margin: 0 10px;
    }
  }
}
