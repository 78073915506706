.landing-page-wrpper {
  //height: 100vh;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #17132a;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 12px;
  .connect-box {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    //background: rgba(255, 255, 255, 0.04);
    // border: 1px solid rgba(255, 255, 255, 0.25);
    // border-radius: 6px;
    display: flex;
    flex-direction: column;
    .connect-wallet-image-wrapper {
      display: flex;
      .landing-logo {
        width: 100px;
        margin: auto;
      }
    }
    .connect-button {
      width: 100%;
      padding-top: 18px;
      padding-bottom: 16px;
      font-family: "Neue Machina bold";
      color: white;
      width: 100%;
      border-radius: 8px;
      text-transform: uppercase;
      font-size: 14px !important;
    }
    // .header-text {
    //   font-family: "Neue Machina bold";
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 40.7395px;
    //   line-height: 57px;
    //   /* identical to box height, or 140% */

    //   text-align: center;

    //   background: linear-gradient(
    //     91.51deg,
    //     rgba(255, 255, 255, 0) -28.58%,
    //     #ffffff 51.99%,
    //     rgba(255, 255, 255, 0) 126.12%
    //   );
    //   -webkit-background-clip: text;
    //   -webkit-text-fill-color: transparent;
    //   background-clip: text;
    // }
    .heading-text {
      font-family: "Neue Machina bold";
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 23px;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin-top: 44px;
      text-align: center;
    }
    .des-text {
      font-family: "Neue Machina regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 175% */

      text-align: center;

      color: #9f9da7;
      margin-top: 16px;
      margin-bottom: 40px;
    }
    .login-line {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .or-text {
      position: absolute;
      color: white;
      top: 75%;
      left: 46%;
      padding: 5px;
      background: #0d0921;
      font-family: "Neue Machina regular";
    }
  }
  .socialmedia-button {
    //padding-top: 50px;
    .button {
      background: rgba(236, 156, 227, 0.09);
      border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
      box-shadow: 0px 0px 8px rgb(196 70 183 / 64%);
      -webkit-backdrop-filter: blur(9.83px);
      backdrop-filter: blur(9.83px);
      padding-top: 18px;
      padding-bottom: 16px;
      font-family: "Neue Machina bold";
      color: white;
      width: 100%;
      border-radius: 8px;
      text-transform: uppercase;
      font-size: 14px;
    }
    .google-button {
      margin-top: 20px;
    }
    .facebook-icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}

.connect-wallet-custom-button {
  .wallet-adapter-button {
    width: 100%;
    text-align: center !important;
    display: inline;
    background: rgba(236, 156, 227, 0.09) !important;
    border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
    box-shadow: 0px 0px 8px rgb(196 70 183 / 64%);
    color: white;
    height: 50px;
    border-radius: 8px;
    text-transform: uppercase;
  }
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
  display: none !important;
}
