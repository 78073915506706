$white: #fff;

.portalSideNav {
  background-color: rgba(255, 255, 255, 0.04);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 29px 27px 48px;
  // width: 17%;
  min-height: 100%;
  height: 100%;
}
.group9227 {
  margin-bottom: 32px;
  position: relative;
  // padding-right: 24px;
  // padding-left: 24px;
}
.logos21 {
  width: 12rem;
  position: relative;
}
.logos1 {
  width: 147px;
  height: 44.24%;
  position: absolute;
  right: 0;
  top: 23px;
}
.group17 {
  border-radius: 6px;
  box-shadow: 0 23px 44px 0 rgba(196, 71, 183, 0.15);
  position: relative;
  border: 1px solid rgba(196, 71, 183, 1);
  filter: blur(6.22px);
}
.rectangle4 {
  width: calc(100% + -2px);
  height: 104.35%;
  background-color: rgba(236, 156, 227, 0.17);
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(196, 70, 183, 0.14), 0 23px 44px 0 rgba(196, 71, 183, 0.15);
  position: absolute;
  left: -1px;
  top: -1px;
  backdrop-filter: blur(9.83px);
}
.frame9165 {
  display: flex;
  align-items: center;
}
.frame9165:hover {
  cursor: pointer;
}
.wallet {
  width: 15px;
  height: 15px;
  color: $white;
  margin-right: 6px;
}
.dashboard {
  font-family: "Neue Machina bold";
  font-size: 12px;
  font-weight: 800;
  line-height: normal;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.frame9164 {
  margin-bottom: 32px;
  margin-left: 16px;
  display: flex;
  align-items: center;
}
.walletTwo {
  width: 15px;
  height: 15px;
  margin-right: 12px;
  color: $white;
}
.frame9161 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.frame9163 {
  margin-bottom: 432px;
  margin-left: 16px;
  display: flex;
  align-items: center;
}
.dateRange {
  margin-right: 10px;
  display: flex;
  align-items: center;
  width: 18px;
  height: 20px;
}
.vector {
  width: 15px;
  height: 15px;
  color: $white;
}
.group9160 {
  margin-left: 23px;
  display: flex;
  align-items: flex-end;
  height: 100%;
  &:not(:last-of-type) {
    margin-bottom: 28px;
  }
}
.side-bar-icon {
  width: 64px;
}

.side-bar-icon:hover {
  cursor: pointer;
}

.group9161 {
  margin-left: 23px;
  display: flex;
  align-items: flex-end;
  &:not(:last-of-type) {
    margin-bottom: 28px;
  }
}
.group9161:hover {
  cursor: pointer;
}
.messages {
  font-family: "Neue Machina bold";
  font-size: 12px;
  font-weight: 800;
  line-height: normal;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.selected {
  border-radius: 6px;
  width: 100%;
  display: flex;
  padding-left: 16px;
  height: 60px;
  margin-bottom: 32px;

  background: rgba(236, 156, 227, 0.17);
  border: 0.01em solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.3) !important;
  backdrop-filter: blur(9.83px);
}

.selected1 {
  border-radius: 6px;
  width: 100%;
  display: flex;
  // padding-left: 16px;
  padding: 10px;
  height: 60px;
  //margin-bottom: 32px;

  background: rgba(236, 156, 227, 0.17);
  border: 0.01em solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.3) !important;
  backdrop-filter: blur(9.83px);
  // margin-top: 25px;
  margin: 0px 10px;
}

.not-selected {
  // padding-left: 16px;
  // margin-top: 32px;
  padding-left: 15px;
  margin: 15px 5px;
}

// *********************** sidebar

#sidebar {
  min-width: 250px;
  max-width: 250px;
  transition: all 0.3s;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: #6d7fcc;
}

#sidebar ul.components {
  padding: 20px 0;
  border-bottom: 1px solid #47748b;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: #7386d5;
  background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.btn-toggleSidebar {
  background: rgba(236, 156, 227, 0.17) !important;
  border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
  box-shadow: 0px 0px 20.0784px rgb(196 70 183 / 30%) !important;
  -webkit-backdrop-filter: blur(9.83px) !important;
  backdrop-filter: blur(9.83px) !important;
  font-size: 12px;
  height: 40px;
  border-radius: 6px;
  padding: 0 10px;
  font-size: 20px;
  margin: 0 5px;
  cursor: pointer;
}

#content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}
