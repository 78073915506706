$white: #fff;
$border: rgba(255, 255, 255, 0.18);

.mall-container {
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.02);
  padding-left: 3%;
  margin-top: 10px;
  display: flex;
  padding-bottom: 28px;
  .mall-balance {
    width: 30%;
    margin-top: 5px;
    .text {
      color: white;
      font-family: "Neue Machina bold";
      font-size: 16px;
    }
    .token {
      font-family: "Neue Machina regular";
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 68%;
    .primary-btn {
      background: rgba(236, 156, 227, 0.17) !important;
      border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
      box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.3) !important;
      backdrop-filter: blur(9.83px) !important;
      font-family: "Neue Machina bold";
      font-size: 12px;
      height: 40px;
      border-radius: 6px;
    }
    .coinbase-button {
      margin-left: 16px;
    }
  }
  .button-wrapper:first-child {
    margin-right: 10px;
  }
  .mal-icon {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
}

.dashboard-container {
  //margin-top: 52px;
  width: 100%;
  background: rgba(255, 255, 255, 0.02);
  //height: 100vh;

  .dashboard-content-wrapper {
    //height: 200px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 10px;
    padding: 42px;
    padding-top: 0px;
    .staking-table {
      margin-top: 28px;
      padding: 28px;
      padding-top: 0px;
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 10px;

      .acc-header {
        display: flex;
        width: 100%;
        align-items: center;
        .amount {
          display: flex;
          justify-content: flex-end;
          width: 91%;
          font-family: "Neue Machina regular";
          margin-bottom: -4px;
        }
        .acc-title {
          font-family: "Neue Machina bold";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0.04em;
          text-transform: uppercase;
        }
        .token-icon {
          height: 16px;
          width: 16px;
          margin-right: 8px;
        }
      }
      .dashboard-dropdown {
        margin-bottom: 20px;
      }

      & .header-title {
        display: flex;
        justify-content: start;
        align-items: center;
      }

      & .buy-button {
        position: absolute;
        right: 30px;
      }
    }
  }
  .view-all {
    border-radius: 29.13px !important;
    font-size: 8px;
    font-family: "Neue Machina bold";
    height: 24px;
    float: right;
    letter-spacing: 0.8px;
  }
  .header-container {
    width: 100%;
    display: flex;
    height: 72px;
    align-items: center;
  }
  .view-all-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 28px;
    width: 100%;
  }
  .side-border {
    border-left: 1px solid rgba(255, 255, 255, 0.25);
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 10px;
  }
  .border-style {
    border-left: 1px solid rgba(255, 255, 255, 0.18) !important;
    border-right: 1px solid rgba(255, 255, 255, 0.18) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18) !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
