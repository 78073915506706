.vestingWrapper {
    background: rgba(255, 255, 255, 0.02);
    .bootstrap-loader {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color:#de72c6 !important
    }
    .side-border {
        //border-radius: 0px !important;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
}