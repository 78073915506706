.experience-wrapper {
  .mall-container {
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.02);
    padding-left: 3%;
    margin-top: 10px;
    display: flex;
    padding-bottom: 28px !important;
    padding-top: 0px !important;
    .mall-balance {
      width: 75%;
      display: flex;
      margin-top: 5px;
      .launcher-wrapper {
        margin-left: 16px;
        margin-top: 16px;
        .text {
          color: white;
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 15px;
          letter-spacing: 0.02em;
        }
        .token {
          font-family: "Neue Machina regular";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          /* identical to box height, or 150% */

          color: #737373;
          margin-top: 6px;
        }
      }
    }
    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      //width: 68%;
       width: 100%;
      .primary-btn {
        background: rgba(236, 156, 227, 0.17) !important;
        border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
        box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.3) !important;
        backdrop-filter: blur(9.83px) !important;
        font-family: "Neue Machina bold";
        font-size: 12px;
        height: 40px;
        border-radius: 6px;
      }
      .coinbase-button {
        margin-left: 16px;
      }
      .download {
        margin-top: 12px;
        margin-right: 16px;
      }
    }
    .button-wrapper:first-child {
      margin-right: 10px;
    }
    .mal-icon {
      width: 18px;
      height: 18px;
      margin-left: 10px;
    }
  }
  .social-media-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 0px !important;
    margin-top: 2rem;
  }
  .socialmedia-button {
    margin-top: 40px;
    .google-login-custom-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .login-button {
        width: 268px;
        height: 48px;
        .login-wrapper {
          display: flex;
          justify-content: center;
          .google-logo {
            width: 20px;
            height: 20px;
          }
          .text {
            margin-left: 12px;
            margin-top: 3px;
          }
        }
      }
    }
    .cancel-button {
      margin-bottom: 24px;
    }
  }
  .or-line-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    .line {
      width: 25%;
      border-top: 0.01em solid rgba(255, 255, 255, 0.18);
    }
    .or-text {
      position: absolute;
      background-color: #0d0921;
      padding: 10px;
      font-style: normal;
      font-family: "Neue Machina regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 175% */

      text-align: center;

      color: #ffffff;
    }
  }
  .create-accout-screen {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    padding-left: 44px !important;
    padding-right: 44px !important;
    padding-bottom: 44px !important;
  }
  .profile-details-button-wrapper {
    display: flex;
    .create-account-button {
      width: 268px;
      height: 48px;
      margin-top: 40px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .create-account-button-big {
    width: 268px;
    height: 48px;
    margin-top: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .download-exe {
    padding-top: 20px !important;
  }
  .verify-email-tag {
    position: relative;
    .email-tag {
      position: absolute;
      // left: 94.5%;
      // bottom: 14%;
      right: 12px;
      top: 40px;
      left: unset;
      bottom: unset;
    }
  }
  .change-password {
    position: absolute;
    // left: 94%;
    // bottom: 10%;
    left: unset;
    bottom: unset;
    right: 12px;
    top: 38px;
  }
  .change-email {
    position: absolute;
    left: 87%;
    bottom: 10%;
  }
}

.alert-model-recharge {
  .modal-content {
    background-color: #17132a !important;
    color: white;
    font-family: "Neue Machina regular";
    padding: 32px;
    border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
    border-radius: 12px;
    .modal-header {
      background: rgba(255, 255, 255, 0.06) !important;
      font-family: "Neue Machina bold";
      border-bottom: 0px;
    }
  }
  .alert-message-wrapper {
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .header-text {
      font-family: "Neue Machina bold";
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 17px;
      letter-spacing: 0.02em;
      color: white;
      margin-top: 36px;
    }
    .sub-text {
      font-family: "Neue Machina regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;
      margin-top: 16px;
      /* or 181% */

      letter-spacing: 0.02em;

      color: #9f9da7;
    }
    .proceed-button {
      margin-top: 36px;
      height: 60px;
      width: 180px;
    }
  }
}

.recharge-loader {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #de72c6;
}

.set-password-wrapper {
  .modal-content {
    background-color: #17132a !important;
    color: white;
    font-family: "Neue Machina regular";
    padding: 32px;
    border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
    border-radius: 12px;
    .modal-header {
      background: rgba(255, 255, 255, 0.06) !important;
      font-family: "Neue Machina bold";
      border-bottom: 0px;
    }
  }
  .header-wrapper {
    display: flex;
  }
  .header-text-wrapper {
    margin-left: 16px;
    margin-bottom: 32px;
    .header-text {
      font-family: "Neue Machina bold";
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 15px;
      letter-spacing: 0.02em;

      color: #ffffff;
    }
    .header-sub-text {
      font-family: "Neue Machina regular";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-top: 8px;

      color: #737373;
    }
  }
  .set-password-button {
    height: 60px;
    margin: auto;
    width: 180px;
    margin-top: 48px;
  }
}

.forgot-password-wrapper {
  .modal-content {
    background-color: #17132a !important;
    color: white;
    font-family: "Neue Machina regular";
    padding: 32px;
    border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
    border-radius: 12px;
    .modal-header {
      background: rgba(255, 255, 255, 0.06) !important;
      font-family: "Neue Machina bold";
      border-bottom: 0px;
    }
  }
  .header-wrapper {
    display: flex;
  }
  .header-text-wrapper {
    margin-left: 16px;
    margin-bottom: 32px;
    .header-text {
      font-family: "Neue Machina bold";
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 15px;
      letter-spacing: 0.02em;

      color: #ffffff;
    }
    .header-sub-text {
      font-family: "Neue Machina regular";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-top: 8px;

      color: #737373;
    }
  }
  .opt-input-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    margin-bottom: 58px;
    .form-control {
      width: 48px;
      height: 48px;
      color: white;
    }
    .form-control:focus {
      background-color: rgba(255, 255, 255, 0.02);
      border: 0.01em solid rgba(255, 255, 255, 0.25);
    }
  }
  .submit {
    margin: auto;
    width: 180px;
    height: 48px;
  }
}

.header-wrapper {
  display: flex;
}
.header-text-wrapper {
  margin-left: 16px;
  margin-bottom: 32px;
  width: 100%;
  .header-text {
    font-family: "Neue Machina bold";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .header-sub-text {
    font-family: "Neue Machina regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;

    color: #737373;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .experience-wrapper {
    .create-accout-screen {
      padding: 20px !important;
    }
  }
}
