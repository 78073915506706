.ddlHeading {
  font-family: "Neue Machina bold";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: #ffffff;
  padding-bottom: 15px;
  padding-top: 40px;
}

.css-qc6sy-singleValue {
  color: #fff !important;
}
