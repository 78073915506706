// $white: #fff;
// $primary-color: #0d0921;
// $border: rgba(255, 255, 255, 0.18);
// $heading: #de72c6;

@import "./components/Color.scss";

$primary: "auto";

:root {
  --base: $primary;
}

#root {
  height: 100%;
  width: 100%;
}

html,
body {
  width: 100%;
}
body {
  display: flex;
  width: 100%;
  background-color: #0d0921;
  overflow-x: hidden;
  overflow-y: var(--base);
}

.app-wrapper {
  display: flex;
  background-color: $primary-color;
  height: 100%;
  width: 100%;
  .router-wrapper {
    display: flex;
    width: 100%;
  }
}

.head-part-wrapper {
  height: 100vh;
  width: 100%;
}

@font-face {
  font-family: "Neue Machina lite";
  src: url("font/NeueMachina-Light.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Neue Machina regular";
  src: url("font/NeueMachina-Regular.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Neue Machina bold";
  src: url("font/NeueMachina-Ultrabold.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

table {
  //border: 1px solid $border;
  //padding: 16px;
  th {
    color: $heading !important;
    font-family: "Neue Machina bold" !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    background: rgba(255, 255, 255, 0.04) !important;
    //border-top: 1px solid red !important;
    //width: 120px
  }
  tbody,
  td,
  tfoot,
  th,
  thead {
    //font-family: "Neue Machina bold" !important;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: $white;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-top: 1px solid $border;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr:last-child {
    border-bottom: 0px solid $border !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-family: "Neue Machina regular";
  }
  tbody tr td {
    //padding-left: 40px !important;
    padding: 2rem !important ;
    padding-left: 42px !important;
  }
  thead tr th {
    padding-left: 40px !important;
  }
}

.accordion {
  color: $white;
  .accordion-item .accordion-button {
    border: 1px solid $border;
    //border-bottom: 0px solid $border;
    color: $white;
    border-radius: 10px;
    border: 1px solid $border;
    background: rgba(255, 255, 255, 0.01) !important;
  }
  .accordion-item {
    background: rgba(255, 255, 255, 0.06) !important;
    margin-top: 15px;
    border-radius: 10px;
    .accordion-header {
      border-radius: 10px;
    }
  }
  .accordion-item:first-of-type .accordion-button {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.01) !important;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.01) !important;
  }
  .accordion-item:first-of-type .accordion-button:not(.collapsed) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .accordion-item:last-of-type .accordion-button:not(.collapsed) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .accordion-button:not(.collapsed) {
    color: $white;
    border-bottom: 0px solid $border;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .accordion-button::after {
    background-image: url("images/DropDown.png") !important;
    color: $white;
    width: 20px;
    height: 20px;
  }
  .accordion-body {
    padding: 0px;
  }
  .accordion-item:first-of-type {
    border-radius: 10px;
  }
  .accordion-item:last-of-type {
    border-radius: 10px;
  }
}

.accordion-button:focus {
  box-shadow: none !important;
}

.table > :not(:first-child) {
  border-top: 1px solid $border !important;
}

.btn-primary {
  background: rgba(236, 156, 227, 0.09) !important;
  border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
  box-shadow: 0px 0px 8px rgba(196, 70, 183, 0.64);
  backdrop-filter: blur(9.83px) !important;
  font-family: "Neue Machina bold";
  border-radius: 6px !important;
  font-size: 12px !important;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: $white;
}

.dropdown {
  .btn-success {
    background-color: $primary-color;
    border: 1px solid rgba(255, 255, 255, 0.25);
    width: 15%;
    text-align: start;
  }
  .btn-success:hover {
    background-color: $primary-color;
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
  .btn-success.dropdown-toggle:focus {
    background-color: $primary-color;
    border: 1px solid rgba(255, 255, 255, 0.25);
    box-shadow: none !important;
  }
}

.app-layout-wrapper {
  width: "100%";
  padding: "52px";
  height: 100%;
  height: 83vh;
  overflow-y: scroll;
  padding: 40px;
  width: 100%;
  padding-top: 52px;
  padding-left: 48px;
  padding-right: 48px;
}

.modal-content {
  background-color: $primary-color !important;
  color: $white;
  font-family: "Neue Machina regular";
  .modal-header {
    background: rgba(255, 255, 255, 0.06) !important;
    font-family: "Neue Machina bold";
    border-bottom: 0px;
  }
}

.alert {
  border-radius: 10px !important;
  position: absolute;
  padding: 0.7rem !important;
  .alert-success {
    font-family: "Neue Machina regular";
  }
}

.table > :not(caption) > * > * {
  //padding: 0.5rem !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(252, 252, 252, 0.1);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(252, 252, 252, 0.1);
}
.cockpit-loader {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #de72c6;
}

.form-control {
  margin-top: 16px;
  background-color: rgba(255, 255, 255, 0.02);
  border: 0.01em solid rgba(255, 255, 255, 0.25);
  border-radius: 6px;
  height: 48px;
  font-family: "Neue Machina regular";
  color: white;
}

.invalid-labellable {
  color: rgb(255, 77, 79);
  font-family: "Neue Machina regular";
  font-size: 14px;
}

.react-confirm-alert-overlay {
  background: transparent !important;

  & .react-confirm-alert-body {
    background-color: #17132a !important;
    color: white;
    font-family: "Neue Machina regular";
    padding: 32px;
    border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
    border-radius: 12px;
    color: #fff;
    width: 500px;

    & .react-confirm-alert-button-group {
      justify-content: center;
    }

    & .btn {
      margin-top: 36px;
      height: 60px;
      width: 180px;
    }
  }
}
.icon {
  font-size: 25px;
  color: #7e4581;
  margin: 0 10px;
  cursor: pointer;
}

.icon-title {
  font-size: 1.5em;
  color: #c446b7;
  opacity: 0.5;
}
.content-center-div {
  margin: auto !important;
  width: auto !important;
  padding: 10px !important;
  text-align: center;
  color: #c446b7;
  opacity: 0.5;
}
.notfound-text {
  font-weight: 400;
  font-family: "Neue Machina bold";
  opacity: 0.5;
  font-size: 18px;
}

.fbx-viewer-wrapper {
  height: 600px;
  &-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

.link-primary {
  cursor: pointer;
  color: #fff;
  &:focus,
  &:hover {
    opacity: 0.8;
    color: #fff !important;
  }
}
