.size-box {
  & .col-auto {
    padding: 0 15px;

    & .input-group {
      margin: 0 !important;
    }
  }
  & span {
    // display: flex;
    // align-items: center;
    // margin-top: 1rem;
    position: relative;
    top: 5rem;
    transform: translateY(-50%);
  }
}

.btn-file-label {
  & svg {
    font-size: 20px;
  }
  & span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
  padding: 8px 15px;
  margin-top: 5px;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.blkHeadingStore {
  & div {
    margin: 0 10px;
  }

  & button {
    margin: 0 20px;
  }
  & .btn {
    float: right;
  }
  & .header-title {
    // display: contents; -- handling desktop screen
    display: inline-block;
  }
}
.card {
  margin: 5px;
  background-color: #201d32;
  border-radius: 10px;

  & .card-body {
    min-height: 120px;

    & .badge {
      background-color: #3d3c4e;
      color: #a9a6b7;
    }

    & .card-title {
      color: #fff;
    }

    & .imgBlock {
      padding: 5px;
      border-radius: 4px;
      height: 100px;
      margin: auto;
      & img {
        max-height: 90px;
        width: 100px;
        display: block;
        grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
        grid-auto-rows: auto;
        margin: 0 auto;
        max-width: 100px;
        gap: 20px;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      }
    }

    & .Publishbadge {
      background-color: #3c3e4a;
      color: #b0e8b1;
      padding: 6px 15px;
      margin: 8px 0px;
      display: inline-block;
      border-radius: 10px;
    }

    & .badge {
      margin: auto 3px;
      border-radius: 0;
    }
  }
  & .btn {
    margin: auto 3px;
    background: #30263f;
    border: 0.01em solid rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 20.0784px rgb(196 70 183 / 30%) !important;
    -webkit-backdrop-filter: blur(9.83px);
    backdrop-filter: blur(9.83px);

    &:active {
      background-color: rgba(236, 156, 227, 0.17);
    }
  }

  & .form-check-input:checked {
    background-color: #da72c6;
    border-color: #da72c6;
  }

  & .form-check-label {
    max-width: 90%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    color: #fff;
  }
}
.btn.active {
  background-color: rgba(236, 156, 227, 0.17) !important;
}

.btn-icon {
  padding: 15px;
  margin: 0 5px;
  & svg {
    font-size: 20px;
  }
}
.btn-lg {
  padding: 0.8rem 1rem;
}

.updateMyStore {
  .header-title {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  img {
    max-width: 200px;
    margin-top: 16px;
    background-color: rgba(255, 255, 255, 0.02);
    border: 0.01em dashed rgba(255, 255, 255, 0.25);
    border-radius: 6px;
  }

  .multiboxSelection {
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: rgba(255, 255, 255, 0.02);
    border: 0.01em solid rgba(255, 255, 255, 0.25);
    border-radius: 6px;
    font-family: "Neue Machina regular";
    color: #a6a5ad;
    padding: 5px 15px;
    width: 100%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: left;
    & label {
      width: 90%;
    }

    & .btn-icon {
      padding: 5px 30px;
    }
  }

  .box-section {
    margin-top: 16px;
    background-color: rgba(255, 255, 255, 0.02);
    border: 0.01em solid rgba(255, 255, 255, 0.25);
    border-radius: 6px;
    font-family: "Neue Machina regular";
    padding: 7px 15px;
    display: grid;
  }

  .udpateStore-sidebox {
    background-color: rgba(255, 255, 255, 0.02);
    border: 0.01em solid rgba(255, 255, 255, 0.25);
    border-radius: 6px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 5px 15px;

    & ul {
      list-style-type: none;
      margin-left: 0;
      & svg {
        margin: 0 15px;
        color: GREEN;
        font-size: 20px;
      }
      & li {
        border-right-style: solid;
      }
    }
  }
}

/* ================ The Timeline ================ */

.timeline {
  position: relative;
  width: 660px;
  padding: 1em 0;
  list-style-type: none;
}

.timeline:before {
  position: absolute;
  left: 27px;
  top: 40px;
  content: " ";
  display: block;
  width: 1px;
  height: 60%;
  margin-left: -3px;
  background: #008000;
  z-index: 5;
}

.timeline li {
  padding: 1em 0;
}

.timeline li:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.direction-l {
  position: relative;
  width: 300px;
  float: left;
  text-align: right;
}

.direction-r {
  position: relative;
  width: 300px;
  float: left;
  z-index: 10;
}

.flag-wrapper {
  position: relative;
  display: inline-block;

  text-align: center;
}

// .direction-l .flag:before,
// .direction-r .flag:before {
//   position: absolute;
//   top: 50%;
//   right: -40px;
//   content: " ";
//   display: block;
//   width: 15px;
//   height: 15px;
//   margin-top: -10px;
//   background: #fff;
//   border-radius: 10px;
//   border: 4px solid rgb(153 96 153);
//   z-index: 10;
// }

.direction-r .flag:before {
  left: -40px;
}

.time-wrapper {
  display: inline;

  line-height: 1em;
  font-size: 0.66666em;
  color: rgb(250, 80, 80);
  vertical-align: middle;
}

.direction-l .time-wrapper {
  float: left;
}

.direction-r .time-wrapper {
  float: right;
}

.time {
  display: inline-block;
  padding: 4px 6px;
  background: rgb(248, 248, 248);
}

/* ================ Timeline Media Queries ================ */

@media screen and (max-width: 660px) {
  .timeline {
    width: 100%;
    padding: 4em 0 1em 0;
  }

  .timeline li {
    padding: 2em 0;
  }

  .direction-l,
  .direction-r {
    float: none;
    width: 100%;

    text-align: center;
  }

  .flag-wrapper {
    text-align: center;
  }

  .flag {
    background: rgb(255, 255, 255);
    z-index: 15;
  }

  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: 50px;
    left: 2%;
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    margin-left: -9px;
    background: #fff;
    border-radius: 10px;
    border: 4px solid rgb(255, 80, 80);
    z-index: 10;
  }

  .direction-l .flag:after,
  .direction-r .flag:after {
    content: "";
    position: absolute;
    left: 10%;
    top: -8px;
    height: 0;
    width: 0;
    margin-left: -8px;
    border: solid transparent;
    border-bottom-color: rgb(255, 255, 255);
    border-width: 8px;
    pointer-events: none;
  }

  .time-wrapper {
    display: block;
    position: relative;
    margin: 4px 0 0 0;
    z-index: 14;
  }

  .direction-l .time-wrapper {
    float: none;
  }

  .direction-r .time-wrapper {
    float: none;
  }

  .direction-l .desc,
  .direction-r .desc {
    position: relative;
    margin: 1em 1em 0 1em;
    padding: 1em;

    z-index: 15;
  }
}

.mall-blkLandSelect {
  width: 75%;
  vertical-align: middle;
  display: table-cell;
  margin: auto;
  .regular {
    padding: 0 5px;
    font-weight: 400;
    font-family: "Neue Machina regular";
  }
  .text {
    color: white;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.02em;
    font-family: "Neue Machina bold";
  }
  img {
    margin-top: 0;
  }
}
.button-wrapper {
  vertical-align: middle;
  margin: auto;
}
.flag-wrapper .flag {
  color: #fff;
}
.modelSelectLand {
  .blkLandSelectionArr {
    text-align: center;
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    cursor: pointer;

    & [class*="col-"] {
      padding: 5px 10px;
    }

    .click-text {
      display: none;
    }
    .bootstrap-loader {
      width: 100%;
      display: inline-block;
    }
    .proceed-button {
      margin-top: 0;
    }

    .header-text-wrapper {
      margin-left: 0;
    }

    .productCard {
      width: 100%;
    }

    .card .card-body {
      min-height: auto;
      padding: 0.2rem 1rem;
      background: rgba(236, 156, 227, 0.09);
      font-family: "Neue Machina normal";
    }
  }
}
