.parent-wrapper{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
  }
  .rot-image-wrapper {
    position: relative;
    margin-left: 37%;
    margin-bottom: -10%;
  }

  .rot-image-wrapper-welcome {
    position: relative;
    margin-left: 25%;
    margin-bottom: -6%;
  }

  .intro-wrapper {
    margin: 0% 8%;
  } 