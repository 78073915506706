.app-layout-wrapper {
  padding-top: 10px;
}
.inventoryPage {
  .tab {
    // border-bottom: 0.01em solid rgba(255, 255, 255, 0.2);
    padding: 0 10px;
    button {
      font-family: "Neue Machina bold";
      border: 0;
      background: transparent;
      color: #fff;
      padding: 10px;
      cursor: pointer;
    }
    .active {
      border-bottom: 0.2em solid #c446b7;
    }
  }

  .cardHeader {
    background: transparent;
    .header-icon {
      margin: 5px;
    }
  }

  .card-header {
    display: flex;
  }

  .blkInventory div {
    margin: 0;
  }
  .blkInventory .header-title {
    display: contents;
  }
  .cardInventoryItems {
    margin: 0 8px !important;
    .row {
      height: 65vh;
      overflow-y: auto;
      // .cube{
      //   font-size: 1.1rem;
      // }

      .namePrice {
        padding: 5px 0;
        text-align: center;
        a {
          margin: 0 auto;
        }
      }
      .digit {
        width: 100%;
        font-size: 12px;
        color: #7e4581;
        font-weight: bold;
      }
    }

    .inputAndActionBox {
      .lable {
        // display: none;
      }
    }

    // ---------use for col-6
    .inputAndActionBox {
      display: flex;
      .input-group,
      button {
        padding: 5px;
        .form-control {
          margin: auto;
          width: 60px;
        }
      }
      button:disabled {
        background-color: "red !important";
      }
    }

    .inputAndActionBoxForTemplate {
      display: flex;
      padding-top: 1rem;
      .input-group,
      button {
        padding: 5px;
        .form-control {
          margin: auto;
          width: 60px;
        }
      }
      button:disabled {
        background-color: "red !important";
      }
    }


  }

  .productCard {
    width: 100%;
    .nft-wrapper {
      min-height: 8rem;
    }
    .image-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .cube {
      // white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .modelCreateInvFurItems {
    .header-title {
      margin: 0;
      width: auto;
      .header-text-wrapper {
        .header-text {
          padding: 5px;
        }
      }
    }
  }

  .modelMoveInvFurItems {
    width: "30%";
    text-align: center;
    .header-title {
      margin: 0;
      width: auto;
      .header-text-wrapper {
        .header-text {
          padding: 5px;
        }
      }
    }
  }
}

.inventoryPageItemsTab {
  .productCard {
    .cube {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin: 15px 0;
    }
    img {
      // max-width: 120px;
      // height: 5rem;
      margin: auto;
      // width: 80%;
      height: 100px;
      object-fit: cover;
      object-position: bottom;
    }

    svg{
      margin: 2px;
    }

    .digit {
      margin: auto 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .header-title .header-icon {
      margin-left: 10px;
    }
    .namePrice {
      padding: 0 15px !important;
      // text-align: left !important;
      svg {
        width: 20px;
        height: 20px;
        color: #c446b7;
      }
    }

    .inputAndActionBox {
      margin: auto;
    }
  }
}

.modelCreateInvFurItems {
  .header-wrapper {
    padding: 0 15px;
  }

  .header-title {
    margin: 0;
    width: auto;
  }

  .header-text-wrapper {
    margin: 0;
    padding: 5px 0;
  }

  .blkLandSelectionArr > div {
    // text-align: center;

    canvas {
      width: 100% !important;
    }
  }
}

.alert-model-showFbxViewer {
  .modal-content {
    padding: 0 !important;
    & .header-text-wrapper {
      padding: 0 !important;
    }
    & .header-wrapper {
      padding: 0 !important;
    }
    & .proceed-button {
      margin-top: 15px !important;
      margin: 15px;
    }
  }
}

.react-confirm-alert-body > h1 {
  color: rgb(251 251 252);
}
