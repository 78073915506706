.staking {
  margin-top: 40px;
  background: rgba(255, 255, 255, 0.02);
  position: relative;
  .bootstrap-loader {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color:#de72c6 !important
  }

  .toast-wrapper {
    position: absolute;
    top: -92%;
    left: 34%;
  }
  .title-wrapper {
    height: 90px !important;
  }
  .header-container {
    width: 100%;
    display: flex;
    height: 90px;
    align-items: center;
  }
}
