$white: #fff;
$primary-color: #0d0921;
$border: rgba(255, 255, 255, 0.18);
$heading: #de72c6;

.token-tranfer {
  background: rgba(255, 255, 255, 0.02);
  .staking-table {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    margin-top: 40px;
    border-radius: 10px;
    .side-border {
      margin-top: 12px;
      border-left: 1px solid rgba(255, 255, 255, 0.25);
      border-right: 1px solid rgba(255, 255, 255, 0.25);
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.02) !important;
    }
    .total-token {
      text-align: center;
    margin-top: 28px;
    color: $white;
    font-family: 'Neue Machina Bold';
    }
  }
  .bootstrap-loader {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color:#de72c6 !important
  }
  .token-icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    margin-left: 5px;
  }
  tbody tr:hover {
    cursor: pointer;
  }
}
