.inputbox-style {
  .lable {
    font-family: "Neue Machina bold";
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.04em;

    color: #ffffff;
    margin-top: 40px;
  }
  .form-control {
    margin-top: 16px;
    background-color: rgba(255, 255, 255, 0.02);
    border: 0.01em solid rgba(255, 255, 255, 0.25);
    border-radius: 6px;
    height: 48px;
    font-family: "Neue Machina regular";
    color: white
  }
  .form-control:disabled{
    color: #514e5e;
  }
  .invalid-labellable {
    color: rgb(255, 77, 79);
    font-family: "Neue Machina regular";
    font-size: 14px;
  }
}
