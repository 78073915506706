.table-wrapper {
    .table-header {
        padding: 32px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        display: flex;
    }
    .mal-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
}
.side-border {
    border-left: 1px solid rgba(255, 255, 255, 0.25);
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 10px;
  }