.custom-select {
  position: relative;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  margin: 0;
  outline: 0;
  padding: 15px !important;
  width: 100%;
  color: #ffffff;
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 0.06);
  border: 0.4px solid rgba(255, 255, 255, 0.7) !important;
  border-radius: 6px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Mina";
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 14px;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: rgb(13 9 32);
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-left: 0.4px solid rgba(255, 255, 255, 0.7) !important;
  border-right: 0.4px solid rgba(255, 255, 255, 0.7) !important;
  border-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom: 0.4px solid rgba(255, 255, 255, 0.7) !important;
  background-color: rgba(13, 9, 32, 0.5);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background: rgba(255, 255, 255, 0.11);
}
