.tabsingle-line {
  /*border-bottom: 0.3px solid #616161;*/
  display: flex;
  align-items: center;
}

.tab-active {
  color: white;
}

.tabsingle {
  display: inline-block;
  padding: 10px 30px;
  color: #fff;
  opacity: 0.6;
}

.targetdiv {
  padding: 30px 0;
}

.tabsingle.br_left.tab-active {
  color: #fff;
  opacity: 1;
  position: relative;
}

.tabsingle.br_left.tab-active:before {
  content: "";
  position: absolute;
  left: 25%;
  bottom: -10px;
  height: 1px;
  width: 50%;
  border-bottom: 4px solid #DE72C6;
  margin: auto;
}