.metamall-collapse-menu {
  &-item {
    border-color: transparent;
    width: 100%;
    background-color: rgba(236, 156, 227, 0.18) !important;
    border-radius: 9px;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    padding: 12px 16px;
    font-weight: 800;
    font-family: "Neue Machina bold";

    button {
      &:focus-visible {
        outline: none !important;
      }
    }

    img {
      height: 10px;
      transform: rotate(180deg);
      transition: transform 0.2s;
    }

    &-active {
      img {
        transform: rotate(270deg) !important;
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
  }

  &-sub-menu {
    padding-top: 10px;
  }

  &-sub-item {
    padding: 12px 16px;
    color: #bababa;
    width: fit-content;
    font-family: "Neue Machina regular";
    font-size: 16px;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      color: white;
      transform: scale(1.05);
    }

  }

  &-active-item {
    color: white;
    transform: scale(1.05);
  }
}