$white: #fff;
$border: rgba(255, 255, 255, 0.18);

.centerpage{

  text-align: center;


  .social-media-wrapper {
    width: 35%;
    height: 300px;
    display: flex;
    flex-direction: column;
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 0px !important;
    margin-top: 2rem;
    border-radius: 12px;  position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    font-family: 'Neue Machina regular';
    padding: 32px;
    margin: auto;
    margin-top: 45vh;   
  }
  .ckpt-logo{
    width: 25%;
    display: flex;
    flex-direction: column;
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 0px !important;
    margin-top: 2rem;
    border-radius: 12px;  position: absolute;
    top:0;
    left: 0;
    right: 0;
    color: white;
    font-family: 'Neue Machina regular';
    margin: auto;
    margin-top: 10vh;   
  }

  .socialmedia-button {
    margin-top: 40px;
    .google-login-custom-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .login-button {
        width: 70%;
        height: 48px;
        .login-wrapper {
          display: flex;
          justify-content: center;
          .google-logo {
            width: 20px;
            height: 20px;
          }
          .text {
            margin-left: 12px;
            margin-top: 3px;
          }
        }
      }
    }
    .cancel-button {
      margin-bottom: 24px;
    }
  }

  .or-line-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    .line {
      width: 25%;
      border-top: 0.01em solid rgba(255, 255, 255, 0.18);
    }
    .or-text {
      position: absolute;
      background-color: #120e25;
      padding: 10px;
      font-style: normal;
      font-family: "Neue Machina regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 175% */

      text-align: center;

      color: #ffffff;
    }
  }


}

.mall-container {
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.02);
  padding-left: 3%;
  margin-top: 10px;
  display: flex;
  padding-bottom: 28px;
  .mall-balance {
    width: 30%;
    margin-top: 5px;
    .text {
      color: white;
      font-family: 'Neue Machina bold';
      font-size: 16px;
    }
    .token {
      font-family: 'Neue Machina regular';
    }

  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 68%;
    .primary-btn {
      background: rgba(236, 156, 227, 0.17) !important;
    border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
    box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.3) !important;
    backdrop-filter: blur(9.83px) !important;
    font-family: 'Neue Machina bold';
      font-size: 12px;
      height: 40px;
    border-radius: 6px;
    }
    .coinbase-button {
      margin-left: 16px;
    }
  }
  .button-wrapper:first-child {
    margin-right: 10px;
  }
  .mal-icon {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
}



.dashboard-container {
  //margin-top: 52px;
  width: 100%;
  background: rgba(255, 255, 255, 0.02);
  //height: 100vh;

  .dashboard-content-wrapper {
    //height: 200px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 10px;
    padding: 42px;
    padding-top: 0px;
    .staking-table {
      margin-top: 28px;
      padding: 28px;
      padding-top: 0px;
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 10px;

      .acc-header {
          display: flex;
          width: 100%;
          align-items: center;
          .amount {
            display: flex;
            justify-content: flex-end;
            width: 91%;
            font-family: 'Neue Machina regular';
            margin-bottom: -4px;
          }
          .acc-title {
            font-family: 'Neue Machina bold';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            
          }
          .token-icon {
            height: 16px;
            width: 16px;
            margin-right: 8px;
          }
      }
      .dashboard-dropdown {
          margin-bottom: 20px;
      }
    }
  }
  .view-all {
    border-radius: 29.13px !important;
    font-size: 8px;
    font-family: 'Neue Machina bold';
    height: 24px;
    float: right;
    letter-spacing: 0.8px;
  }
  .header-container {
    width: 100%;
    display: flex;
    height: 72px;
    align-items: center;
  }
  .view-all-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 28px;
    width: 100%;
}
  .side-border {
    border-left: 1px solid rgba(255, 255, 255, 0.25);
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 10px;
  }
  .border-style {
    border-left: 1px solid rgba(255, 255, 255, 0.18) !important;
    border-right: 1px solid rgba(255, 255, 255, 0.18) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18) !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}



/* Viji css starts*/
.nav-container {
  margin-top: 115px;
}


/* iPhone landscape and iPad portrait */
@media screen and (max-device-width: 767px) and (orientation: landscape) {
  .centerpage .social-media-wrapper {
    bottom: 0;
    height: 250px;
    margin: 45vh auto auto;
    padding-top: 0!important;
    /* padding: 0; */
}
.centerpage .social-media-wrapper {
  width: 50%;
}

.centerpage .ckpt-logo, .centerpage .social-media-wrapper  {
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 0!important;
}

.centerpage .ckpt-logo {
  width: 35%;
  margin-top: 0vh;
}

}



@media all and (min-width:769px) and (max-width: 800px){


  .centerpage .social-media-wrapper {
    width: 60%;
    margin-top: 25vh;
}
.centerpage .ckpt-logo {
  width: 50%;
  margin-top: 0;
}


}
/* Viji css ends*/