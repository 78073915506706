.land-center-wrapper {
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 10px;
  padding: 42px;
  padding-top: 0px;
  position: relative;
  width: 100%;
  .toast-message {
    position: absolute;
    left: 35%;
    top: -8%;
    font-family: "Neue Machina regular";
  }
  .land-trans {
    background: rgba(255, 255, 255, 0.02);
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 10px;
    margin-top: 40px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    .header-container {
      display: flex;
      width: 100%;
      .view-all-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        .view-all {
          border-radius: 29.13px !important;
          font-size: 8px;
          height: 24px;
          float: right;
          margin-top: 2.7%;
        }
      }
    }
  }
  .land-owned {
    background: rgba(255, 255, 255, 0.02);
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 10px;
    margin-top: 40px;
    padding-left: 20px;
    padding-bottom: 20px;
    .land-wrapper {
      display: flex;
      flex-wrap: wrap;
      .product-card-wrapper {
        //height: 230px;
      }
    }
    img {
      // margin-right: 5px;
    }
    .titleLabel {
      display: contents;
    }
  }
  .bootstrap-loader {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #de72c6 !important;
  }
  .buy-button-style {
    margin-right: 38px !important;
  }
  .land-owned-title {
    margin-left: 13px;
  }
}
