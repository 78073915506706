$small: 600px;
$medium: 1200px;
$large: 1600px;

.marketplace {
  width: 100vw;

  &-heading {
    color: #fff;
    font-family: NeueB;
    font-weight: 800;
    margin-bottom: 28px;

    span {
      font-family: NeueL;
      font-weight: 400;
    }
  }

  &-search-input>span {
    height: 48px;
    background-color: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 8px;

    .ant-input-affix-wrapper {
      box-shadow: none;
      height: 45px;
      background-color: rgba(255, 255, 255, 0.04);
      border: none;
    }

    input {
      background-color: transparent;
      border: none;
      color: white;
    }

    .ant-input-group-addon {
      display: none;
    }

    svg {
      fill: white;
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border: none;
      box-shadow: none;
    }

    .ant-input-clear-icon {
      height: 23px;
    }

    &:focus-visible {
      outline: none;
    }
  }

  a {
    color: transparent;
  }

  &-banner {
    padding-top: 166px;
    padding-bottom: 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url(../../img/marketplacebanner.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-bottom: 2px solid rgba(255, 255, 255, 0.14);

    &-content {
      padding: 0 7vw;

      &-subheading {
        font-family: NeueL;
        font-size: 16px;
        margin-bottom: 44px;
        color: #bababa;
      }

      &-buttons {
        display: flex;
        gap: 16px;

        button {
          width: 118px !important;
          height: 48px;
        }
      }
    }

    &-collapsed {
      position: fixed;
      top: 0;
      width: 100vw;
      padding: 0;
      padding-top: 0; /* Viji css padding-top: 115px;*/

    }
  }

  &-listing {
    padding: 0px 7vw;
    padding-top: 80px;

    &-store {
      &-no-data {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: #fff;

        p {
          font-family: NeueB;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 800;
        }
      }

      &-card {
        width: 15%;
        padding: 8px;
        padding-bottom: 40px;
        transition: transform 0.2s;
        text-align: center;
        margin: 0 auto;

        @media screen and (max-width: $medium) {
          width: 15%;
        }

        @media screen and (max-width: $small) {
          width: 25%;
        }
      }

      &-wrapper {
        text-align: center;

        & img {
          width: 100px;
          height: 100px;
          margin: 10px 0;
          border-radius: 50%;
          border: 1px solid #332d45;
          padding: 5px;
          display: inline-block;
          vertical-align: middle;
        }
      }

      &-title {
        font-family: "Neue Machina bold";
        font-size: 16px;
        font-weight: 800;
        color: #bababa;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 24px;
      }
    }

    &-trade-history {
      border: 1px solid rgba(255, 255, 255, 0.18);
      border-radius: 10px;
      overflow: hidden;

      &-title {
        font-family: monospace;
        font-weight: 800;
        font-size: 1.5rem;
      }
    }

    &-collapsed {
      display: flex;
      margin-top: 115px;
      padding-top: 50px;
      gap: 20px;
      justify-content: space-between;
      /* height: calc(100vh - 115px);*//* Viji css page scrolling not working, so hide this css*/
      overflow: auto;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-categories {
      color: #9f9da7;
      font-family: NeueB;
      font-size: 12px;
      text-transform: uppercase;
      padding-left: 12px;
      letter-spacing: 0.14em;
      margin-bottom: 28px;
    }

    &-view_all_btn {
      padding: 7px 14px;
    }

    &-section-left {
      width: 25%;
      max-width: 300px;
    }

    &-section-right {
      width: 75%;
      padding-top: 48px;
    }

    &-cards-container {
      display: flex;
      width: 100%;
      padding-bottom: 50px;

      &-full-view {
        display: flex;
        width: 100%;
        margin-top: 30px;
        flex-wrap: wrap;
        padding-bottom: 30px;
      }
    }

    &-no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90%;

      p {
        font-family: NeueB;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 800;
      }
    }

    &-card {
      width: 25%;
      padding: 20px;
      transition: transform 0.2s;

      @media screen and (max-width: $large) {
        width: 33%;
      }

      @media screen and (max-width: $medium) {
        width: 50%;
      }

      @media screen and (max-width: $small) {
        width: 100%;
      }

      &-wrapper {
        border-radius: 8px;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.04);
      }

      &-banner {
        margin-bottom: 18px;
        width: 100%;
        border-radius: 8px;
        aspect-ratio: 1 / 1;
        object-fit: cover;
      }

      &-title {
        font-family: "Neue Machina bold";
        font-size: 16px;
        font-weight: 800;
        color: white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 24px;
      }

      &-price {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 12px;

        img {
          width: 16px;
        }

        span {
          font-family: "Neue Machina regular";
          font-size: 16px;
          font-weight: 300;
          line-height: normal;
          color: white;
        }
      }

      &-label {
        font-family: "Neue Machina regular";
        font-size: 12px;
        font-weight: 300;
        color: #bababa;
      }

      &-sell-btn {
        width: 100%;
        height: 40px;
      }
    }

    &-tags {
      display: flex;
      align-items: center;
      gap: 8px;
      min-height: 22px;

      p {
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 4px;
        padding: 2px 10px;
        font-family: "Neue Machina regular";
        font-size: 12px;
        font-weight: 300;
        color: white;
        opacity: 0.8;
        letter-spacing: 0.2px;
        margin-bottom: 0;
      }
    }

    &-detail {
      margin-top: 115px;
      /*height: calc(100vh - 115px);*/ /*Viji Css, commented for page not scrolling*/
      overflow-y: auto;
      overflow-x: hidden;

      &-safe-area {
        padding: 7vw;
      }

      &-card {
        border-radius: 8px;
        padding: 40px;
        border: 1px solid rgba(255, 255, 255, 0.14);
        background-color: rgba(255, 255, 255, 0.04);
        display: flex;
        gap: 60px;
        align-items: center;
        margin-bottom: 32px;
      }

      &-img {
        border-radius: 8px;
        width: 35%;
      }

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 500px;
        width: 100%;
      }

      &-title {
        font-family: NeueB;
        font-weight: 800;
        font-size: 28px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: white;
        margin-bottom: 24px;
      }

      &-price {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 12px;
        margin: 32px 0;

        img {
          width: 16px;
        }

        span {
          font-family: NeueB;
          font-weight: 800;
          font-size: 18px;
          letter-spacing: 0.04em;
          color: white;
        }
      }

      &-label {
        font-family: "Neue Machina regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.14em;
        text-transform: uppercase;
        color: #9f9da7;
        margin: 32px 0 16px 0;
      }

      &-owner {
        font-family: NeueB;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #e686d1;
        margin: 0;
        word-break: break-all;/* Viji Css */
      }

      &-description {
        font-family: "Neue Machina regular";
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #ffffff;
      }

      &-itemsInStoreCard {
        & .card {
          height: 190px;
        }
      }
    }
  }

  // .blkallStore {
  //   margin-top: 25vh;
  //   margin-left: auto;
  //   margin-right: auto;

  //   & .col-auto {
  //     width: 12%;
  //   }

  //   & .marketplace-listing-card {
  //     width: 25% !important;
  //   }

  //   & img {
  //     display: block;
  //     margin-left: auto;
  //     margin-right: auto;
  //     width: 50%;
  //   }
  // }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .scale-on-hover {
    &:hover {
      transform: scale(1.06);
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 50px;

    img,
    svg {
      fill: white !important;
      vertical-align: unset;
    }

    .ant-pagination-item:hover a {
      color: #e686d1
    }

    li,
    button,
    .ant-select,
    .ant-select-selector {
      background-color: rgba(255, 255, 255, 0.04) !important;
      color: white;
      border: 1px solid rgba(255, 255, 255, 0.18) !important;
      border-radius: 15px !important;
    }

    .ant-select {
      border: none !important;
    }

    .ant-pagination-item-ellipsis,
    .ant-select-arrow {
      color: white !important;
    }

    .ant-pagination-item-active {
      border-color: #e686d1 !important;
    }
  }

  .no-margin {
    margin: 0;
  }

  .create-nft-btn {
    margin-top: 50px;
    width: 100%;
    background-color: rgba(236, 156, 227, 0.18) !important;
    border-radius: 9px;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    padding: 12px 16px;
    font-weight: 800;
    font-family: "Neue Machina bold";
  }

  .storeDetailsInfo p.marketplace-listing-detail-title {
    margin-bottom: 10px;
    font-size: 20px;
    text-transform: capitalize;
  }

  .storeDetailsInfo p.marketplace-listing-detail-label {
    margin: 7px 15px;

    .marketplace-heading.image {
      vertical-align: top;
    }
  }

  .marketplace-listing-cards-container .col-auto {
    width: 160px;
  }

  .linkIcon span {
    font-size: 24px;
    color: #fff;
    padding: 5px;
    cursor: pointer;
  }

  &-input {
    background-color: rgba(255, 255, 255, 0.02);
    border: 0.01em solid rgba(255, 255, 255, 0.25);
    border-radius: 6px;
    height: 48px;
    font-family: "Neue Machina regular";
    color: white;
  }
}


/* *****************************Viji CSS Starts*********************************** */

@media only screen and (min-width: 320px) and (max-width: 767px) {
  
  .marketplace-listing-detail-img {
    width: 100% !important;
}
.marketplace-listing-detail-card {
  padding: 20px;
  border: 2px solid rgba(255, 255, 255, 0.14) !important;
  background-color: rgba(255, 255, 255, 0.04);
}
.marketplace-listing-detail-card, .marketplace-listing-detail-content {
  display: grid !important;
}
.marketplace-listing-detail-content {
  height: auto !important; 
}
.marketplace-listing-detail-owner {
  word-break: break-all;
}

	/* Force table to not be like tables anymore */
	/*.marketplace-listing-trade-history table, .marketplace-listing-trade-history thead, .marketplace-listing-trade-history tbody, .marketplace-listing-trade-history th, .marketplace-listing-trade-history td, .marketplace-listing-trade-history tr { 
		display: block; 
	}*/
	
	/* Hide table headers (but not display: none;, for accessibility) */
	.marketplace-listing-trade-history thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
    color: #de72c6 !important;
    background: rgba(255, 255, 255, 0.04) !important;
	}
  .marketplace-listing-trade-history table tbody, .marketplace-listing-trade-history table thead, table tr:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.18) !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-family: "Neue Machina regular";
}

	
	.marketplace-listing-trade-history td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50% !important; 
    word-break: break-all;
    padding-left: 5px !important;
	}
	
  .marketplace-listing-trade-history table tbody tr td {
    padding: 2rem !important;
    padding-left: 5px !important;
    text-align: left !important;
    line-height: 20px !important;
}
.marketplace-listing-trade-history table tr {
  border-top: 2px solid rgba(255, 255, 255, 0.18) !important;
}
	.marketplace-listing-trade-history td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45% !important; 
		padding-right: 10px; 
		white-space: nowrap;
    color: #de72c6 !important;
    text-align: left;
	}
	
	/*
	Label the data
	*/
	.marketplace-listing-trade-history td:nth-of-type(1):before { content: "SIGNATURE"; }
	.marketplace-listing-trade-history td:nth-of-type(2):before { content: "BUYER"; }
	.marketplace-listing-trade-history td:nth-of-type(3):before { content: "TIME"; }



  /*label ul {
    top: 350px !important;
  }*/
}




/* *****************************Viji CSS Ends************************************ */

