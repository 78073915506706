.box {
  position: relative;
  overflow: hidden;
  width: 573px;
  height: 415px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #a8abb3;
  border-radius: 10px;
}

iframe {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
/*#ytvideoControl source
{
    height: 600px;
  }  
*/

/* #ytvideoControl {
  perspective: 1000px;
}*/
#ytvideoControl {
  /*transform: rotateY(-30deg);*/
  -ms-transform: scaleY(1.5); /* IE 9 */
  transform: scaleY(1.5);
}
video#ytvideoControl {
  object-fit: fill;
}

#marketplace .yeah {
  margin-top: -26vw;
}
#marketplace .top-center {
  display: grid;
  grid-template-columns: 100%;
  height: 80vh;
  place-items: center;
  position: absolute;
  top: 0!important;
  width: 100%;
}
.checkout-body section {
  min-height: 88vh;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  #marketplace .yeah {
    margin-top: -85vw;
  }
  .top-center p {
    font-size: 17.0052px;
    line-height: 16px;
    margin-top: -85vh;
  }

  .token-stakingbox .line:after {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    right: 0 !important;
    bottom: 0px !important;
    top: 10% !important;
    left: 0;
    right: 0;
    box-shadow: none;
  }
}

.content-container {
  margin-top: 120px;
}
@media (max-width: 1440px) {
  .content-container {
    margin-top: 10%;
  }
}
@media (max-width: 900px) {
  .content-container {
    margin-top: 20%;
  }
}
