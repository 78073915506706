@font-face {
  font-family: Neue;
  src: url(../font/NeueMachina-Regular.otf);
}

@font-face {
  font-family: NeueL;
  src: url(../font/NeueMachina-Light.otf);
}

@font-face {
  font-family: NeueB;
  src: url(../font/NeueMachina-Ultrabold.otf);
}

@font-face {
  font-family: Mina;
  src: url(../font/Mina-Regular.ttf);
}
@font-face {
  font-family: Mortend;
  src: url(../font/Mortend-Bold.otf);
}
* {
  box-sizing: border-box;
}
/* width */

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #d8d8d8;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: rgb(19, 19, 19);
}
.clearfix:after {
  content: "";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

.container {
  position: relative;
  /* width: 100vw; */
  /* overflow-x: hidden; */
}
.text-center {
  text-align: center !important;
}
.logo > img {
  cursor: pointer;
}

.nav {
  position: fixed;
  top: 0;
  width: 100vw;
  display: grid;
  grid-template-columns: 40% 20% 40%;
  place-items: center;
  /* margin: 20px auto; */
  padding: 10px 7vw;
  color: white;
  z-index: 5;
  box-sizing: border-box;
  background: transparent;
  font-family: NeueB, sans-serif;
  font-size: 0.8em;
}

.nav > .left {
  justify-self: left;
}

.nav > .right {
  justify-self: right;
}

.nav > div > p,
.nav > div > div {
  position: relative;
  display: inline-block;
  margin: 0px 15px;
  cursor: pointer;
  font-family: NeueB, sans-serif;
}
.nav > div > p,
.nav > div > div:first-child {
  margin-left: 0px !important;
}

.nav > div > div {
  position: relative;
  /* margin: 20px auto; */
  width: max-content;
  padding: 15px 20px;
  cursor: pointer;
  border: solid 1px white;
}

.logo > img {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.overlay:nth-of-type(1) {
  z-index: 1 !important;
}

.overlay.top {
  z-index: 0;
}

.center:nth-of-type(2) {
  z-index: 2;
}

.dark {
  background-color: #fbfaf8 !important;
}

.lotus-right {
  position: absolute;
  top: 10%;
  right: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.lotus-left {
  position: absolute;
  top: 10%;
  left: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.lotus-left > img {
  position: absolute;
  transform: translateX(-40%);
}

.lotus-right > img {
  position: absolute;
  right: -100px;
}

.splide__slide > img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.burger {
  position: fixed;
  top: 40px;
  right: 20px;
  height: 25px;
  width: 35px;
  z-index: 21;
  cursor: pointer;
}

.samp {
  height: 40vh;
}

.mob-nav {
  position: fixed;
  height: calc(var(--vh, 1vh) * 100);
  /* height: 100vh; */
  width: 100%;
  clip-path: ellipse(0% 0% at 50% 0%);
  display: grid;
  grid-template-rows: 90% 10%;
  justify-items: center;
  align-items: center;
  pointer-events: none;
  z-index: 20;
  background: #0d0921;
  text-align: center;
}

.mob-nav > .links > button {
  font-size: 2rem;
  margin: 0 auto;
  margin-bottom: 50px;
  border: none;
  background: transparent;
  display: block;
  text-align: center;
  color: white;
  font-family: NeueL, Courier, monospace;
}

.nline {
  position: absolute;
  width: 90%;
  margin: 0 auto;
  /* margin-top: 10px; */
  height: 2px;
  /* left: 50%;
    top: 50%;
    transform: translate(-50%,-50%); */
  background-color: white;
  transform-origin: left;
}

.nline:nth-of-type(1) {
  top: 40%;
  /* top: 0;
    transform: rotate(45deg); */
}

/* .line:nth-of-type(2) {
    top: 60%;
  } */

.nline:nth-of-type(2) {
  top: 80%;
  /* top: 90%;
    transform: rotate(-45deg); */
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  color: white;
}

.container {
  position: relative;
  /* width: 100vw; */
  /* overflow-x: hidden; */
}

.logo > img {
  cursor: pointer;
}

.logo > img {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.socials {
  position: relative;
  display: flex;
  column-gap: 30px;
}

.overlay {
  display: grid;
  grid-template-columns: 10% 80% 10%;
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.noverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  display: grid;
  grid-template-columns: 10% 80% 10%;
}

.overlay > .liner {
  z-index: 12;
  opacity: 1;
}

.parallax {
  /* position: fixed; */
  /* width: 100vw; */
  /* display: flex;
    justify-content: center;
    left: 0;
    top: 0; */
  z-index: -1;
  height: 100vh;
}

.parallax > video,
.slide-bg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.parallax-img .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0d0921;
  opacity: 0.6;
  z-index: 11;
  display: grid;
  grid-template-columns: 10% 80% 10%;
}

.overlayy {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /* background: #071E6F; */
  background: black;
  opacity: 0.5;
}

.slide-grid {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.img {
  position: relative;
}

.img > img:not(.rot),
.img > div > img:not(.rot) {
  width: 80%;
}

.img > div {
  position: relative;
}

p {
  font-family: NeueL, Courier, monospace;
}

.bold {
  font-family: NeueB, Courier, monospace;
}

.h1 > b {
  font-family: NeueB, Courier, monospace;
}

.boldHeading {
  font-family: NeueB, Courier, monospace;
  font-size: 5.2rem;
  margin-bottom: 10px;
}

.overlay-new {
  /* position: absolute; */
  pointer-events: none;
  z-index: 7;
  bottom: 0;
  width: 100vw;
  height: 30vh;
  background: linear-gradient(0deg, #000000b2 0%, rgba(0, 0, 0, 0) 80%);
}

.landing {
  position: relative;
  text-align: center;
  align-self: center;
}

.landing > h1 {
  font-family: NeueB, Courier, monospace;
  font-size: 5.2rem;
  margin: 0;
  margin-bottom: 10px;
}

.landing > p {
  font-size: 1.3rem;
}

.yeah {
  position: relative;
  height: 100%;
  width: 100vw;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  background-color: #0d0921;
}
/* 
section:not(.without) {
    background: linear-gradient(0deg, #000000b2 0%, rgba(0, 0, 0, 0) 80%);
} */

.center {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  place-items: center;
}

.top-nav {
  position: fixed;
  display: grid;
  top: 0;
  left: 0;
  width: 100vw;
  grid-template-columns: 50% 50%;
  padding: 30px 6vw;
  align-items: center;
  font-size: 1.2rem;
  z-index: 10;
  color: white;
}

.top-nav.show-center {
  grid-template-columns: 30% 40% 30%;
}

.top-nav > .left {
  justify-self: left;
}

.top-nav > .center {
  opacity: 0;
  display: none;
  justify-self: center;
  text-align: center;
}

.top-nav > .center > h1 {
  font-size: 2.1rem;
  margin: 0;
  margin-bottom: 5px;
}

.top-nav > .center > p {
  font-size: 0.5rem;
  margin: 0;
}

.top-nav > .left > button {
  border: none;
  background: transparent;
  color: white;
  font-weight: 800;
  font-family: NeueB, Courier, monospace;
  margin-right: 20px;
  font-size: 1rem;
}

.top-nav > .right {
  justify-self: right;
}

.nav > .right > img {
  margin-right: 25px;
}

.explore {
  margin: 40px auto;
  width: max-content;
  padding: 15px 50px;
  background: transparent;
  color: white;
  font-size: 1.2rem;
  font-family: Neue, Courier, monospace;
  pointer-events: all;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 24px 4px rgba(255, 198, 239, 0.25);
  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
}

.liner {
  position: relative;
  height: 100%;
  width: 0px;
  background-color: rgba(255, 255, 255, 0.5);
  /* border: 0.1px solid #FFFFFF;*/
  box-shadow: 0px 0px 3px #cdf0ff;
}

.landsale-body .liner {
  position: relative;
  height: 100%;
  width: 0.1px;
  background-color: rgba(255, 255, 255);
  /* border: 0.1px solid #FFFFFF;*/
  box-shadow: 0px 0px 3px #cdf0ff;
}

.middle-line {
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  opacity: 0.25;
  width: 0.1px;
  border: none;
  box-shadow: none;
}

.block {
  position: absolute;
  height: 90px;
  width: 0px;
  opacity: 1;
  background-color: #fff;
  /* box-shadow:
        0 0 0px 1px #fff,
        0 0 20px 4px #fff,
        0 0 140px 50px #CDF0FF; */

  box-shadow: 0px 0px 10px #ffffff;
  /* outer cyan */
  left: 0px;
  z-index: 1;
}

.left-block {
  top: 5%;
}

.right-block {
  top: 15%;
}

.left-line {
  justify-self: right;
}

.right-line {
  justify-self: left;
}

.content-container {
  position: relative;
  display: block;
  z-index: 2;
}

section {
  font-family: Neue, Courier, monospace;
  margin: 0 auto;
  min-height: 60vh;
  position: relative;
  width: 100%;
}

.yeah h1 {
  font-size: 2.75rem;
  line-height: 3rem;
  margin: 0;
}

.yeah p {
  margin-top: 30px;
  font-size: 1.2rem;
  line-height: 2.1rem;
}

.grey {
  font-family: NeueB, Courier, monospace;
  color: #ffffff;
  opacity: 0.6;
}

.left {
  justify-self: left;
  text-align: left;
}

.right {
  justify-self: right;
  text-align: right;
}

.img {
  /* justify-self: center; */
  /* width: max-content;
    margin: 0 auto; */
}

.rot {
  position: absolute;
  top: 0;
  transform: translate(0, -50%);
}

.intro,
.intro2,
.coin,
.heart,
.joys {
  display: grid;
  grid-template-columns: 100%;
  place-items: center;
  width: 100%;
  height: max-content;
  min-height: 80vh;
}

.intro > div,
.intro2 > div:nth-of-type(1),
.coin > div,
.heart > div,
.joys > div {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  margin: 0 8%;
}

.intro2 {
  min-height: 100vh;
}
.intro2 .right,
.heart .right {
  text-align: right;
}

.wrap {
  width: 100vw;
  margin: 400px 0 200px 0;
}

.wrap > div {
  /* position: absolute; */
  display: flex;
  width: max-content;
  left: -200px;
}

.li {
  display: flex;
  align-items: center;
}

.li > p {
  font-size: 3.25rem;
  margin: 0 30px;
  margin-top: 6px;
  line-height: 3rem;
}

p.small {
  font-size: 1.3rem;
  color: #de72c6;
  margin: 10px 0;
}

.li > div {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: white;
}

.roadmap {
  display: grid;
  grid-template-columns: 100%;
  place-items: center;
  width: 100%;
  height: max-content;
  min-height: 100vh;
  margin-top: 20px;
}

.roadmap > div,
.roadmap > div > img {
  width: 100%;
}

.team,
.nfts,
.floor-maps {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  width: 100%;
  height: max-content;
  min-height: 100vh;
  padding: 80px 0 100px 0;
  margin-top: 100px;
}
.partners {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  width: 100%;
  height: max-content;
  min-height: 100vh;
  padding: 40px 0 100px 0;
  margin-top: 100px;
}

/*.team>div:first-of-type, .partners>div:first-of-type {
    padding-left: 5%;
}
*/
.team > .dudes,
.nfts > .dudes {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  place-items: center;
}
.floor-maps > .dudes3 {
  display: grid;
  grid-template-columns: 30.33% 39.33% 30.33%;
  width: 100%;
  place-items: center;
  margin-top: -5%;
}

.nfts > .dudes1,
.contact > .dudes,
.token-claim > .dudes {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  place-items: center;
}

.nfts > .dudes1 .dude {
  /*width:45%;*/
  margin: -1% auto;
  width: 50%;
}
.dudes > img {
  display: block;
  width: 90%;
  margin: 0 auto;
}

.dude {
  position: relative;
  background: rgba(255, 255, 255, 0.07);
  border: 0.6px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 44px rgba(205, 240, 255, 0.2);
  backdrop-filter: blur(4px);
  min-height: 700px;
  width: 85%;
  margin: 20px auto;
  border-radius: 20px;
  text-align: center;
  padding: 40px 7%;
}
.nfts .dude {
  position: relative;
  background: none;
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  backdrop-filter: none;
  min-height: auto;
  width: 85%;
  margin: -5% auto;
  border-radius: 20px;
  text-align: center;
  padding: 40px 7%;
}

.floor-maps .dude {
  position: relative;
  background: none;
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  backdrop-filter: none;
  min-height: auto;
  width: 85%;
  margin: 0 auto 20px auto;
  border-radius: 20px;
  text-align: center;
  padding: 0 0 40px 0;
}
.legend-new p {
  margin-top: -5%;
  font-weight: bold;
}

.mt-40 {
  margin-top: 40%;
}
.mt--20 {
  margin-top: -20%;
}
.nfts .dude > p {
  margin-top: 0;
}
.dude > h1 {
  font-size: 1.6rem;
  margin: 15px 0;
  text-transform: uppercase;
}

.dude > h3 {
  font-family: NeueB, sans-serif;
  font-size: 1.1rem;
  text-transform: uppercase;
  line-height: 1.8rem;
}

.investors,
.contact {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  place-items: center;
  width: 90%;
  min-height: 50vh;
  margin: 0 auto;
}

.investors > div:last-of-type {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 20%);
  margin: 150px auto;
  place-items: center;
  /* width: 90%; */
}

.contact {
  min-height: 20vh;
  text-align: center;
}

.contact > div:last-of-type {
  position: relative;
  /* display: grid;
    grid-template-columns: repeat(2, 50%); */
  margin: 50px 0;
  place-items: center;
  text-align: center;
}

.contact > div:last-of-type > a {
  color: #06e5ff;
  text-decoration: underline;
  cursor: pointer;
}

.investors > div:last-of-type > img {
  position: relative;
  width: 80%;
  margin: 0 auto;
  display: block;
}

.mobile {
  display: none;
}

.row-partners {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
}

.col-partners {
  flex: 1 0 18%; /* The important bit. This percentage decides your columns. 
 The percent can be px. It just represents your minimum starting width.
  */
  margin: 5px;
  height: 200px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact a {
  color: #fff;
}
.col-partners img {
  width: 295px;
}
.col-partners1 {
  justify-content: flex-end;
}
.col-partners2 {
  justify-content: flex-start;
}
.top-center {
  position: absolute;
  top: 10vh;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  place-items: center;
}
.top-center p {
  color: #fff;
  font-family: Neue, Courier, monospace;
  font-size: 20.6609px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  margin-top: none;
  margin-bottom: -10%;
  text-align: center;
  text-transform: uppercase;
}
.top-center p span {
  font-weight: 400;
}

.yeah#content-landsale {
  position: relative;
  height: 100%;
  width: 100vw;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  background-color: transparent;
}
.yeah-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0d0921;
  opacity: 0.9;
  z-index: 11;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  z-index: 1;
}
.yeah-overlay1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: 11;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  z-index: 1;
  background: none
}
.summary,
.prologue,
.nfts,
.contact {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  width: 100%;
  height: max-content;
  min-height: 70vh;
  padding: 0 0 100px 0;
  margin-top: 100px;
}
.prologue-dude {
  position: relative;
  background: rgba(255, 255, 255, 0.07);
  border: 0.6px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 44px rgb(205 240 255 / 20%);
  backdrop-filter: blur(4px);
  min-height: auto;
  width: 40%;
  margin: 20px auto;
  border-radius: 20px;
  text-align: center;
  padding: 40px 7%;
}
.parent {
  margin: 1rem;
  /*padding: 2rem 2rem;*/
  padding: 2rem 2rem 6rem;
  text-align: center;
}
.child {
  display: inline-block;
  padding: 1rem 1rem;
  width: 180px;
  max-width: 180px;
  vertical-align: middle;
  margin: 10px 2rem;
  background: rgba(255, 255, 255, 0.02);
  border: 0.3px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 9.20741px rgb(205 240 255 / 20%);
  backdrop-filter: blur(7.11481px);
  border-radius: 14.19px;
}
.parent p {
  font-family: Neue, Courier, monospace;
  font-style: normal;
  font-weight: 800;
  font-size: 11.8365px;
  line-height: 11px;
  text-transform: uppercase;
  color: #c546b7;
  margin-top: 0;
}
.parent h1 {
  font-family: Mortend;
  font-style: normal;
  font-weight: bold;
  font-size: 33.9312px;
  line-height: 37px;
  text-transform: uppercase;
  color: #ffffff;
}
.table-div {
  padding: 1rem 6rem;
}
/*table {
	border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    border: 0.3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 9.20741px rgb(205 240 255 / 20%);
    backdrop-filter: blur(7.11481px);
    border-radius: 14.19px;
}
table tr {
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}
table th {
    border: 1px solid #ddd;
    font-family: Neue, Courier, monospace;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #8863D6;
    background: rgba(236, 156, 227, 0.07);
}
table td {
	font-size: 18px;
    line-height: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	border: 1px solid #ddd;
}*/
table.roundedCorners {
  border: 0.4px solid #ffffff;
  border-radius: 16px;
  border-spacing: 0;
  background: rgba(255, 255, 255, 0.02);
  table-layout: fixed;
  width: 100%;
  backdrop-filter: blur(47.11px);
  font-size: 15px;
  font-family: NeueL, Courier, monospace;
}
table.roundedCorners td,
table.roundedCorners th {
  border-bottom: 0.4px solid #ffffff63;
  padding: 10px;
  /*font-weight: bold;*/
  /*line-height: 25px;*/
}
table.roundedCorners th {
  color: #8863d6;
  padding: 35px 10px;
  font-family: NeueB, Courier, monospace;
}
table.roundedCorners tr:last-child > td {
  border-bottom: none;
}
thead {
  background: rgba(236, 156, 227, 0.07);
}
.bl {
  border-left: 0.4px solid #ffffff63;
  font-family: Mina;
  letter-spacing: 1px;
  text-align: center !important;
}
.iconLabel {
  position: relative;
  top: -10px;
}
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}
.row:after {
  content: "";
  display: table;
  clear: both;
}

.contact-dude,
.floor-dude {
  position: relative;
  background: rgba(255, 255, 255, 0.07);
  border: 0.6px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 44px rgb(205 240 255 / 20%);
  backdrop-filter: blur(4px);
  min-height: auto;
  width: 60%;
  margin: 20px auto;
  border-radius: 20px;
  text-align: center;
  padding: 40px 7%;
}
.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

.floor-dude ul {
  list-style-type: none;
  display: inline;
}
.floor-dude li {
  display: inline;
  margin: 0 20px;
}

.floor-dude .img-responsive {
  vertical-align: -5px;
}
.floor-dude div {
  margin: 0 -10%;
}

.nfts .dude p {
  margin-top: -10%;
}
.table-div .img-responsive {
  vertical-align: -5px;
}
.partner-container img {
  width: 300px;
}
.partner-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  text-align: center;
}

.partner-block {
  height: 200px;
  width: 300px;
  margin: 10px;
}

.loader {
  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader > img {
  width: 100px;
}

.loader.hidden {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.thumb {
  height: 100px;
  border: 1px solid black;
  margin: 10px;
}

.token-claim,
.token-staking-last {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  width: 100%;
  height: max-content;
  min-height: 70vh;
  padding: 0;
  margin-top: 180px;
}
.token-claim-dude {
  position: relative;
  border: 0.6px solid #ffffff;
  box-sizing: border-box;
  min-height: 700px;
  width: 40%;
  margin: 20px auto;
  text-align: center;
  padding: 20px 4%;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 0px 0px 34px rgb(205 240 255 / 7%);
  border-bottom: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
}

/* .buy-land-style {
  position: relative;
  border: 0.6px solid #ffffff;
  box-sizing: border-box;
  min-height: 700px;
  width: 40%;
  margin: 20px auto;
  text-align: left;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 0px 0px 34px rgb(205 240 255 / 7%);
  border-bottom: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
} */

.buy-land-style {
  position: relative;
  box-sizing: border-box;
  min-height: 700px;
  margin: 20px auto;
  text-align: left;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 0px 0px 34px rgb(205 240 255 / 7%);
  border-bottom: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
}
.tokenclaim-body {
  background: #0d0921;
}
.buy-land-style form {
  padding: 20px 0;
}
.token-claim .boldHeading,
.token-staking-last .boldHeading {
  font-family: NeueB, Courier, monospace;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 23px;
  color: #ffffff;
  margin-bottom: 30px;
}
.token-claim .boldHeading img,
.token-staking-last .boldHeading img {
  height: 40px;
  vertical-align: middle;
}
.token-claim .boldHeading span,
.token-staking-last .boldHeading span {
  font-weight: 100 !important;
  font-family: NeueL, Courier, monospace;
}
.token-claim-dude p,
.checkoout-token-claim-dude p {
  text-align: left;
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}
.buy-land-style p,
.buy-land-style p span {
  font-family: NeueL, Courier, monospace;
  /*font-weight: 800;*/
  font-size: 14px;
  line-height: 22px;
  margin: 12px 0;
}
.buy-land-style span span {
  font-family: Mina;
  font-weight: 100;
}
.buy-land-style span.right {
  font-size: 12px;
  line-height: 22px;
  font-weight: 100;
  letter-spacing: 0.02em;
  color: #a9ffa1;
}
.token-claim-dude button img,
.checkoout-token-claim-dude button img,
.buy-land-style button img {
  vertical-align: middle;
  height: 25px;
}
.token-claim-dude button {
  color: white;
  line-height: 15px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.06);
  border: 0.4px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  width: 100%;
  text-align: left;
  margin: 15px 0;
  font-family: NeueL, Courier, monospace;
  font-size: 16px;
  font-weight: 400;
}

.buy-land-style button {
  color: white;
  line-height: 24px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.06);
  border: 0.4px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  width: 100%;
  text-align: left;
  font-family: NeueL, Courier, monospace;
}
.token-claim-dude button:hover,
.checkoout-token-claim-dude button:hover,
.buy-land-style button:hover {
  background: rgba(255, 255, 255, 0.09);
  cursor: pointer;
}
.buy-land-style button span {
  float: right;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  padding: 0 5px;
}
.buy-land-style label {
  width: 100%;
}
.token-claim-dude button span::after,
.checkoout-token-claim-dude button span::after {
  font-family: FontAwesome;
  font-size: 18px;
  float: right;
  content: "\f105"; /* this is your text. You can also use UTF-8 character codes as I do here */
  font-family: FontAwesome;
  left: -5px;
  top: 0;
  margin-top: 5px;
}

.token-claim-dude button.watch-video,
.checkoout-token-claim-dude button.watch-video {
  width: auto;
  background: rgba(236, 156, 227, 0.17);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.41);
  backdrop-filter: blur(9.83px);
  border-radius: 59.13px;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.09em;
  color: #ffffff;
}
.token-claim-dude button.watch-video:hover,
.checkoout-token-claim-dude button.watch-video:hover {
  background: rgba(236, 156, 227, 0.27);
}
.token-claim-dude button.watch-video img,
.checkoout-token-claim-dude button.watch-video img {
  vertical-align: middle;
  height: 19px;
}
.token-claim-dude button.watch-video span:after,
.checkoout-token-claim-dude button.watch-video span:after {
  display: none;
}
.left {
  float: left;
}
.right {
  float: right;
  text-align: center;
}
.buy-land-style .left img {
  height: 20px;
  vertical-align: text-bottom;
}

.token-claim-dude .para,
.checkoout-token-claim-dude .para {
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #b3b3b3;
}
/*Form Styling*/

.buy-land-style input[type="text"],
.buy-land-style input[type="number"],
.buy-land-style select {
  border: none;
  font-size: 16px;
  height: auto;
  margin: 0;
  outline: 0;
  padding: 15px;
  width: 100%;
  color: #ffffff;
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 0.06);
  border: 0.4px solid rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Mina";
}

.buy-land-style label {
  font-family: NeueL, Courier, monospace;
  display: inline-block;
  margin-bottom: 1em;
  font-size: 12px;
  line-height: 12px;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  opacity: 0.5;
}

.buy-land-style .icon {
  padding-left: 45px !important;
  background: url(../imgg/m-icon.png) rgba(255, 255, 255, 0.06) no-repeat scroll
    10px 15px !important;
  background-size: 20px !important;
}

.buy-land-style label.inline,
input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin-bottom: 1em;
}

.buy-land-style select {
  height: 50px;
}

.buy-land-style input::-webkit-input-placeholder {
  color: #999999;
  font-style: italic;
  text-align: right;
}

.buy-land-style input:focus {
  outline: 0;
}

.claim-last-img {
  height: 22px;
  vertical-align: text-bottom;
}
/*Media Query*/

@media (min-width: 768px) {
  #tab,
  .buy-land-style input,
  .buy-land-style select {
    display: inline;
  }
}
.buy-land-style ul.login {
  margin: 0;
  list-style: none !important;
  padding: 0;
}

.buy-land-style ul.login li {
  display: table-cell;
}
.buy-land-style .pl0 {
  padding-left: 10px !important;
}
.buy-land-style .pr0 {
  padding-right: 10px !important;
}

.buy-land-style .custom-search {
  position: relative;
}
.buy-land-style .custom-search-input {
  width: 100% !important;
  line-height: 1 !important;
  box-sizing: border-box;
  outline: none !important;
  font-family: NeueL, Courier, monospace;
}
.buy-land-style .custom-search-botton {
  width: auto !important;
  position: absolute;
  right: 3px;
  top: 15px;
  right: 10px;
  border: 0 !important;
  color: #fff !important;
  outline: none;
  margin: 0;
  padding: 0 10px !important;
  z-index: 2;
  background: rgba(255, 255, 255, 0.15) !important;
  border-radius: 6px !important;
  font-weight: 800 !important;
}
.buy-land-style .registerbtn {
  background: rgba(236, 156, 227, 0.17) !important;
  box-sizing: border-box;
  box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.41);
  backdrop-filter: blur(9.83px);
  border-radius: 9.13px !important;
  width: auto !important;
  text-align: center;
  padding: 20px !important;
  margin: 10px auto;
  display: block;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.buy-land-style .registerbtn .mina-font {
  font-family: "Mina";
  background: none;
  padding: none;
  float: normal;
}
.buy-land-style .registerbtn:hover {
  background: rgba(236, 156, 227, 0.27) !important;
  cursor: pointer;
}
.buy-land-style .registerbtn-reverse {
  background: rgba(236, 156, 227, 0.04) !important;
  border: 0.4px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.41);
  backdrop-filter: blur(9.83px);
  border-radius: 9.13px !important;
  width: auto !important;
  text-align: center;
  padding: 20px !important;
  margin: 10px auto;
  display: block;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.buy-land-style .registerbtn-reverse:hover {
  background: rgba(236, 156, 227, 0.01) !important;
  cursor: pointer;
}

.token-claim-last-dude .registerbtn,
.token-claim-last-dude .registerbtn-reverse {
  padding: 0 !important;
  margin-bottom: 25px;
  width: 200px;
  padding: 20px !important;
}
.token-claim-last-dude .registerbtn-reverse img {
  height: 18px;
  vertical-align: bottom;
}
.buy-land-style .hr-line {
  padding: 20px 0;
}
.buy-land-style .hr-line img {
  width: 100%;
  padding: 0;
}

.vesting-head {
  text-align: center;
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 30px;
  margin-top: 0;
}

.vesting-head img {
  height: 25px;
  vertical-align: text-bottom;
}
.table-vesting table.roundedCorners {
  border: none;
  border-radius: 0;
  border-spacing: 0;
  background: none;
  table-layout: fixed;
  width: 100%;
  backdrop-filter: none;
  font-size: 15px;
  font-family: NeueL, Courier, monospace;
}
.table-vesting table.roundedCorners th {
  background: none;
  border-bottom: 0.4px solid #ffffff63;
  padding: 15px;
  border-top: 0.4px solid #ffffff63;
  text-align: center !important;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px !important;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #de72c6;
}
.table-vesting table.roundedCorners thead {
  background: none;
}
.table-vesting table.roundedCorners td {
  border: none;
  text-align: center !important;
  font-family: Mina;
  padding: 15px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
}
.green {
  color: #a9ffa1;
}
.claimed {
  background: rgba(203, 255, 198, 0.15);
  border-radius: 6px;
  padding: 5px 8px;
  font-family: Neue, Courier, monospace;
}
.grey {
  color: #ffffff;
  opacity: 0.6;
}
.upcoming {
  font-family: Neue, Courier, monospace;
  color: #ffa26d;
  background: rgba(255, 189, 152, 0.15);
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 8px;
}
.buy-land-style.token-claim-last-dude {
  text-align: center;
}
.token-last-img {
  margin-left: 50px;
  margin-top: 20px;
}
.buy-land-style p.claimed-text {
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 30px;
}
.buy-land-style p.claimed-text .trxHash {
  font-family: "Mina";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.buy-land-style p.claimed-text span {
  font-family: "Mina";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.balance-text {
  padding-bottom: 40px;
}

#show_bg_2 {
  background-image: radial-gradient(
    farthest-corner at 47px 53px,
    rgba(14, 10, 35, 34.3) 3%,
    rgba(14, 10, 35, 0.4) 69%
  );
  /* background-image: linear-gradient(165deg, rgba(14, 10, 35, 0.3), rgba(14, 10, 35, 0.3)), url(imgg/bg.png); */
  width: 90%;
  min-height: 450px;
  background-size: cover;
  color: white;
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#show_bg_m {
  background-image: radial-gradient(
    farthest-corner at 47px 53px,
    rgba(14, 10, 35, 34.3) 3%,
    rgba(14, 10, 35, 0.4) 69%
  );
  /* background-image: linear-gradient(165deg, rgba(14, 10, 35, 0.3), rgba(14, 10, 35, 0.3)), url(imgg/bg-mobile.png); */
  width: 90%;
  min-height: 500px;
  background-size: cover;
  color: white;
  padding: 35px;
  display: flex;
  /*align-items: center;*/
  justify-content: center;
}

/*#show_bg_2 .overlay1 {
			margin:0;
			background: radial-gradient(rgba(14, 10, 35, 34.3), rgba(14, 10, 35, 0.4));
			background-size: auto, cover;
			background-position:center;
		}*/
#show_bg_2 .right {
  width: 573px;
  height: 415px;
  /*background-image: url(imgg/bg.png);
			background-repeat: no-repeat;*/
}
#show_bg_m .box {
  width: 100%;
  height: 170px;
  margin-top: 15px;
  /*background-image: url(imgg/bg-m.png);
			background-repeat: no-repeat;*/
}
.half {
  width: 50%;
}
.video-sec {
  display: grid;
  grid-template-columns: 100%;
  place-items: center;
  width: 100%;
  height: max-content;
  min-height: 70vh;
  margin-top: 80px;
}
.video-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
}
.video-sec .left {
  padding: 20px;
  width: 100%;
}
.video-sec > div,
.video-sec > div > img {
  width: 100%;
}
.video-sec h1 {
  font-family: Neue, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 47px;
  line-height: 51px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 800;
  margin: 20px 0;
}
.video-sec h1 span {
  font-weight: 100;
}
.video-sec p.desc {
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 16.82px;
  line-height: 36px;
  color: #ffffff;
}
.video-sec p {
  font-family: Neue, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 15.82px;
  line-height: 36px;
  color: #b4b4b4;
}
.video-sec .right button {
  margin: auto;
  display: block;
  background: none;
  border: none;
}
.video-sec .right button:hover {
  cursor: pointer;
}

#btn-close-modal {
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #949494;
  margin-top: 43%;
  font-size: 45px;
}

.modal-content {
  margin: 0 auto;
  width: 60%;
  /*box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 7px 20px 0 rgb(0 0 0 / 17%);*/
}
.modal-content iframe {
  width: 100%;
  height: 550px;
  border: none;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 7px 20px 0 rgb(0 0 0 / 17%);
  /* animation-name: modalopen; */
}
.video-sec a#demo02 {
  margin: auto;
  display: block;
  background: none;
  border: none;
  margin-left: 20%;
}

/*----------------NFT Marketplace------------------------*/

.tabs-section .tab {
  overflow: hidden;
  background-color: none;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 73%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Style the buttons inside the tab */
.tabs-section .tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-family: Neue, Courier, monospace;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 15px;
  color: #ffffff;
}

/* Change background color of buttons on hover */
.tabs-section .tab button:hover {
  border-bottom: 4px solid #de72c6;
}

/* Create an active/current tablink class */
.tabs-section .tab button.active {
  border-bottom: 4px solid #de72c6;
}

/* Style the tab content */
.tabs-section .tabcontent {
  padding: 6px 12px;
  border-top: none;
  margin-top: 5%;
  /* position: absolute;*/
  width: 100%;
}

.centered {
  text-align: center;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #444444;
  padding-top: 77px;
  display: none;
}

.refreshbtn {
  background: rgba(236, 156, 227, 0.17);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.14);
  backdrop-filter: blur(9.83px);
  border-radius: 9.13px;
  width: 60px;
  height: 60px;
}
.refreshbtn img {
  height: 18px;
  margin: auto;
  margin-top: 5px;
}
.refreshbtn:hover {
  background: rgba(236, 156, 227, 0.27) !important;
  cursor: pointer;
}
.backbtn {
  background: rgba(236, 156, 227, 0.17);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.14);
  backdrop-filter: blur(9.83px);
  border-radius: 9.13px;
  width: 48px;
  height: 48px;
}
.backbtn img {
  height: 14px;
  margin: auto;
  margin-top: 5px;
}
.backbtn:hover {
  background: rgba(236, 156, 227, 0.27) !important;
  cursor: pointer;
}
.tabcontent .dude {
  position: relative;
  background: none;
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  backdrop-filter: none;
  min-height: auto;
  width: 85%;
  margin: -5% auto;
  border-radius: 20px;
  text-align: center;
  padding: 40px 7%;
}

.tabcontent > .dudes {
  display: grid;
  grid-template-columns: 5% 63% 32%;
  width: 100%;
  place-items: center;
  padding: 0 6.5%;
  padding-bottom: 40px;
}
#add {
  padding: 17px;
  padding-left: 55px;
  width: 300px;
  border: 1px solid #f5f5f5;
  font-size: 13px;
  color: gray;
  background-image: url("http://i47.tinypic.com/r02vbq.png");
  background-repeat: no-repeat;
  background-position: left center;
  outline: 0;
}

.tabs-section input.nosubmit {
  width: 97%;
  padding: 9px 6px 9px 50px;
  background-size: 20px;
  height: 60px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  font-family: Neue, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  color: #ffffff;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ffffff;
}

.tabs-section select#recent-select {
  background: rgba(255, 255, 255, 0.06);
  border: 0.4px solid rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  width: 90%;
  height: 60px;
}
/*the container must be positioned relative:*/
.tabs-section .custom-select {
  position: relative;
  width: 100%;
}

.tabs-section .custom-select select {
  display: none; /*hide original SELECT element:*/
}

.tabs-section .select-selected {
  margin: 0;
  outline: 0;
  padding: 22px !important;
  height: 60px;
  width: 100%;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.25) !important;
  border-radius: 6px;
  font-size: 16px;
  line-height: 14px;
  font-family: NeueL, Courier, monospace;
  margin-bottom: 0;
}

/*style the arrow inside the select element:*/
.tabs-section .select-selected:after {
  position: absolute;
  content: "";
  top: 25px;
  right: 14px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.tabs-section .select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 17px;
}

/*style the items (options), including the selected item:*/
.tabs-section .select-items div,
.select-selected {
  color: #ffffff;
  padding: 12px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 14px;
}

/*style items (options):*/
.tabs-section .select-items {
  position: absolute;
  background-color: rgb(13 9 32);
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-left: 0.4px solid rgba(255, 255, 255, 0.7) !important;
  border-right: 0.4px solid rgba(255, 255, 255, 0.7) !important;
  border-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom: 0.4px solid rgba(255, 255, 255, 0.7) !important;
  background-color: rgba(13, 9, 32, 0.5);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

/*hide the items when the select box is closed:*/
.tabs-section .select-hide {
  display: none;
}

.tabs-section .select-items div:hover,
.same-as-selected {
  background: rgba(255, 255, 255, 0.11);
}

.nft-list {
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  margin: 30px 6.5%;
}
.nft-list > .dudes {
  display: grid;
  grid-template-columns: 40% 60%;
  width: 100%;
  place-items: center;
}
.nft-list div {
  margin: auto;
}
.nft-details {
  padding: 24px 20px 15px 20px !important;
}
.nft-img {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 10px !important;
}
.nft-img img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
}

.nft-details h1 {
  font-family: NeueB, Courier, monospace;
  font-weight: 800;
  font-size: 24px;
  line-height: 23px;
  color: #ffffff;
}
.nft-details ul {
  display: flex;
  list-style: none;
  padding: 0;
}
.nft-details ul li {
  display: inline;
  margin-right: 10px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.nft-details p {
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 10px;
  margin-bottom: 12px;
}
.nft-list .dudes .dudes {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
}
.nft-list .dudes .dudes div {
  padding: 0;
  margin: 0;
}
.nft-list .dudes .dudes .leftDiv {
  place-items: flex-start;
}
.nft-list .dudes .dudes .rightDiv {
  display: flex;
  justify-content: flex-end;
}

.nft-details .leftDiv p {
  font-family: Mina;
  font-size: 18px;
  line-height: 29px;
  color: #ffffff;
}
.nft-list .dudes .dudes div img {
  vertical-align: middle;
}

.buy-land {
  background: rgba(236, 156, 227, 0.17);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.47);
  backdrop-filter: blur(9.83px);
  border-radius: 9.13px;
  font-family: NeueB, Courier, monospace;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 50px;
  height: 65px;
  margin-top: 10px;
  margin-right: 5px;
}
.buy-land:hover {
  background: rgba(236, 156, 227, 0.27) !important;
  cursor: pointer;
}

.checkout-section {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  width: 100%;
  height: max-content;
  /* min-height: 20vh;*/
  padding: 0;
  margin-top: 40px;
  /*margin-top: 180px;*/
}
.checkout-content > .dudes {
  display: grid;
  grid-template-columns: 30% 40% 30%;
  width: 100%;
  padding: 0 6.5%;
}
.checkout-head {
  display: flex;
  justify-content: center;
}
.checkout-head p {
  font-family: NeueB, Courier, monospace;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 23px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 0;
}
.checkout-head p span {
  font-family: NeueL, Courier, monospace;
  font-weight: 400;
}

.checkout-box,
.token-stakingbox {
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  margin: 0 6.5%;
  margin-top: 10px;
}
.checkout-box > .dudes,
.token-stakingbox > .dudes {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
}
/*.checkout-box div {
    margin: auto;
}*/
.token-stakingbox .checkout-details,
.token-staking {
  padding-top: 15px !important;
  text-align: left;
}
.checkout-img {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 55px 50px 50px 50px !important;
}
.checkout-img .checkout-mainimg,
.checkout-img .token-mainimg {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
}

.checkout-details h1 {
  font-family: NeueB, Courier, monospace;
  font-weight: 800;
  font-size: 24px;
  line-height: 23px;
  color: #ffffff;
  text-align: left;
  padding: 10px 0;
}
.checkout-details ul {
  display: flex;
  list-style: none;
  padding: 0;
}
.checkout-details ul li {
  display: inline;
  margin-right: 10px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.checkout-details p {
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  padding: 10px 0;
}
.checkout-details .pb0 {
  padding-bottom: 0 !important;
}
.checkout-box .dudes .dudes {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
}
.checkout-box .dudes .dudes div {
  padding: 0;
  margin: 0;
}
.checkout-box .dudes .dudes .leftDiv {
  place-items: flex-start;
}
.checkout-box .dudes .dudes .rightDiv {
  display: flex;
  justify-content: flex-end;
}

.checkout-details .leftDiv p {
  font-family: Mina;
  font-size: 18px;
  line-height: 29px;
  color: #ffffff;
}
.checkout-box .dudes .dudes div img {
  vertical-align: middle;
}
.checkout-details p span {
  margin-right: 20px;
}
.checkout-details p span img {
  height: 20px;
  vertical-align: middle;
}

.checkout-details .token-claim-dude {
  position: relative;
  border: none;
  box-sizing: border-box;
  min-height: auto;
  width: 100%;
  margin: 0px auto;
  text-align: center;
  padding: 20px 8%;
  background: none;
  box-shadow: none;
  border-bottom: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.vertLine {
  border-right: 1px #ff0000; /* line 1 pixel width, length of "Some content" */
}
.line {
  padding-right: 21px; /* 20+1 */
  position: relative;
}
.line:after {
  content: "";
  position: absolute;
  right: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  top: 9%;
  bottom: 9%;
  box-shadow: none;
}
.checkout-details .token-claim-dude button {
  margin: 20px 0;
}
.checkout-details .wallet-head {
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 17px;
  color: #ffffff;
}

.wallet-num {
  font-family: Mina;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  color: #ffffff;
}

.checkout-details {
  padding-top: 24px !important;
  text-align: left;
}

.token-staking h1 {
  font-family: NeueB, Courier, monospace;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 20px 0;
}
.token-stakingbox .dudes .dudes {
  display: grid;
  grid-template-columns: 60% 40%;
  width: 100%;
}
.token-stakingbox .checkout-img {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 45px 40px 40px 40px !important;
}
.token-staking .dude,
.stakingpools .token-staking .dude {
  position: relative;
  background: none;
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  backdrop-filter: none;
  min-height: auto;
  width: 100%;
  margin: 2px auto;
  border-radius: 20px;
  text-align: center;
  padding: 0 1%;
}
.token-staking .dude p,
.stakingpools .token-staking .dude p {
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.7);
  text-align: left;
  margin: 6px 0;
}
.token-staking .right-dude p,
.stakingpools .token-staking .right-dude p {
  text-align: right;
}

.token-staking img,
.stakingpools .token-staking img {
  vertical-align: middle;
}
.token-staking .dude p.num,
.stakingpools .num {
  font-family: "Mina";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.7);
}
.token-stakingbox .line:after {
  content: "";
  position: absolute;
  right: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  top: 6%;
  bottom: 6%;
  box-shadow: none;
}

.checkout-details .buy-land-style .token-num {
  font-family: Mina;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.checkout-details .buy-land-style .normal-font {
  font-family: NeueL, Courier, monospace;
  background: none;
}

.token-stakingbox .buy-land-style {
  position: relative;
  border: none;
  box-sizing: border-box;
  min-height: 700px;
  width: 100%;
  margin: 20px auto;
  text-align: left;
  padding: 20px auto;
  background: none;
  box-shadow: none;
  border-bottom: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
}
.tokenstaking,
.stakingpools {
  padding: 20px 5% 0;
}
.tokenstaking .checkout-head p,
.stakingpools .checkout-head p {
  margin-bottom: 4%;
}

.tokenstaking > .dudes {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
}
.tokenstaking .dude {
  position: relative;
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  box-shadow: none;
  backdrop-filter: blur(4px);
  min-height: auto;
  width: 85%;
  margin: 20px auto;
  border-radius: 20px;
  text-align: center;
  padding: 40px 7%;
}
.stakingpools .token-stakingbox {
  background: none;
  border: none;
  margin: 10px 6.5% 0;
}
.stakingpools .token-stakingbox > .dudes {
  display: grid;
  grid-template-columns: 45% 10% 45%;
  width: 100%;
}
.stakingpools .dude {
  position: relative;
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  box-shadow: none;
  backdrop-filter: blur(4px);
  min-height: auto;
  width: 100%;
  margin: 20px auto;
  border-radius: 10px;
  text-align: center;
  padding: 40px 8% 30px 8%;
}
.stakingpools .dude h1 {
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 19px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

h1.stake-tokens {
  justify-content: center;
}

.stakingpools .dude h1 span {
  font-weight: normal;
}
.stakingpools .dude img {
  height: 200px;
}
.stakingpools .buy-land {
  width: 90%;
  height: 50px;
  margin-bottom: 12px;
}

.stakingpools .token-stakingbox .checkout-img {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 !important;
}

.stakingpools .token-staking .dude p.num img {
  height: 18px;
}
.stakingpools .token-stakingbox .line:after {
  content: "";
  position: absolute;
  right: 0;
  border-right: none;
  top: 0;
  bottom: 0;
  box-shadow: none;
}

.stakingpools .dude img.token-mainimg {
  height: auto;
}

.stakingpools .btn-100 {
  margin-top: 30px;
  width: 100%;
}
.stake-green {
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: #a8fca3;
  background: rgba(168, 252, 163, 0.15);
  border-radius: 6px;
  padding: 5px;
}
.stake-orange {
  font-family: NeueL, Courier, monospace;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.02em;
  color: #ffae63;
  background: rgba(255, 174, 99, 0.15);
  border-radius: 6px;
  padding: 5px;
}

.buy-land-style span.right-text {
  float: right;
  font-size: 12px;
  line-height: 22px;
  font-weight: 100;
  letter-spacing: 0.02em;
  color: #a9ffa1;
  justify-self: right;
  text-align: right;
}

.metamall-coin {
  height: 120px;
  margin-top: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .metamall-coin {
    height: 80px;
    margin-top: 10px;
  }
  .buy-land-style span .right-text {
    text-align: right !important;
  }
  .stakingpools .token-stakingbox .line:after {
    content: "";
    position: initial;
    right: 0;
    border-right: none;
    top: 0;
    bottom: 0;
    box-shadow: none;
  }
  .token-stakingbox .line:after {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    right: 0 !important;
    bottom: -10px !important;
    top: 10% !important;
    left: 0;
    right: 0;
    box-shadow: none;
  }
  .buy-land-style button {
    font-size: 14px;
  }
  .token-staking h1 {
    margin: 35px 0 20px 0;
  }
  .checkout-img .token-mainimg {
    padding-bottom: 0px;
  }
  .token-staking {
    padding-top: 0 !important;
  }
  .token-staking .dude p.num,
  .token-staking .dude p,
  .stakingpools .token-staking .dude p.num,
  .stakingpools .token-staking .dude p {
    font-size: 11px;
    line-height: 18px;
    margin: 0;
  }
  #show_bg_2 {
    padding: 10px;
  }
  .video-sec a#demo02 {
    margin-top: 120px;
    background: none;
    border: none;
  }

  .close:hover {
    cursor: pointer;
  }
  .clear {
    clear: both;
  }
  .video-sec .right {
    padding: 10px;
  }
  .video-sec .left {
    padding: 0;
  }
  .video-sec p {
    line-height: 26px;
  }
  .video-sec h1 {
    font-size: 35px;
  }
  .video-sec p.desc {
    line-height: 28px;
  }
  #btn-close-modal {
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: #949494;
    margin-top: 4%;
    font-size: 45px;
  }
  #modal-02 .modal-content {
    margin: 0 auto;
    width: 80%;
  }
  #modal-02 #btn-close-modal {
    margin-top: 100%;
  }
  .modal-content iframe {
    width: 100%;
    height: 350px;
    border: none;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 7px 20px 0 rgb(0 0 0 / 17%);
    /* animation-name: modalopen; */
  }
}

@media (max-width: 1440px) {
  .token-claim,
  .token-staking-last {
    margin-top: 16%;
  }
  .token-claim-dude,
  .buy-land-style {
    width: 50%;
    min-height: 626px;
  }
}

@media (max-width: 1500px) {
  .nav {
    padding: 10px 10vw;
  }
}

@media (max-width: 1300px) {
  .nav {
    padding: 10px 5vw;
  }

  .nav > div > p,
  .nav > div > div {
    margin: 0px 6.5px;
  }
}

/*@media(max-width: 1000px) {
    section:nth-of-type(6)>div:first-of-type {
        grid-template-columns: 30% 70%;
        column-gap: 20px;
    }

}*/

@media (max-width: 900px) {
  html {
    /*background: url(images/MobileBG.png) no-repeat 50% center #0D0921 fixed !important; 
		  -webkit-background-size: cover;
		  -moz-background-size: cover;
		  -o-background-size: cover;
		  background-size: cover;
		  background:url("images/MobileBG.png") no-repeat scroll right top #0D0921;
            -o-background-size: cover;
            -moz-background-size: cover;
            -webkit-background-size:cover;
            background-size: cover;*/
  }

  :root {
    font-size: 13px;
  }

  .mobile.grid {
    display: grid;
  }

  .no-mobile {
    display: none;
  }

  .nav {
    grid-template-columns: 40% 20% 40%;
    padding: 10px 5vw;
  }

  .center > img {
    width: 80vw;
  }

  .top-center > img {
    width: 80vw;
  }

  .nav {
    position: absolute;
    background: transparent !important;
  }

  .nav .left{
    justify-self: center;
  }

  .overlayy {
    height: calc(var(--vh, 1vh) * 100);
  }

  .slides {
    background: none;
  }

  .img {
    justify-self: center;
    width: max-content;
    margin: 0 auto;
  }

  .wrap {
    margin: 100px 0;
  }

  .middle-line {
    opacity: 0.15;
  }

  .top-nav > .center > h1 {
    font-size: 1.7rem;
  }

  .top-nav > .center > p {
    font-size: 0.8rem;
  }

  .top-nav > .left > button {
    display: block;
    border: none;
    background: transparent;
    color: white;
    font-weight: 800;
    font-family: NeueB, Courier, monospace;
    margin-bottom: 10px;
    font-size: 0.9rem;
  }

  .top-nav > .right > img {
    margin-right: 10px;
    width: 20px;
  }

  .img {
    order: 2;
  }

  .landing > h1 {
    font-size: 4.2rem;
  }

  .slide-grid {
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .yeah,
  .slide-grid,
  .noverlay {
    grid-template-columns: 2% 96% 2% !important;
  }
  .block {
    background: none;
    border: none;
    box-shadow: none;
  }
  .intro > .left,
  .intro > .right {
    position: relative;
    left: initial;
    bottom: initial;
    top: initial;
    transform: none;
    justify-self: center;
  }

  .intro > .left > img,
  .intro > .right > img {
    width: 70%;
    display: block;
    margin: 0 auto;
    mix-blend-mode: luminosity;
  }

  section {
    height: initial;
    min-height: 110vh;
  }

  .without {
    min-height: 100vh;
  }

  .parallax > video {
    min-height: 100vh;
    width: 100%;
    object-fit: cover;
  }

  .intro > .left {
    grid-area: b;
  }

  .intro > .right {
    grid-area: c;
  }

  .intro > .middle {
    padding: 50px 10vw 0 10vw;
  }

  .intro {
    padding: 120px 0 0 0;
    margin-bottom: 60px;
    grid-template-areas: "a a" "b c";
  }

  .top-nav {
    padding: 20px 3vw;
  }

  .top-nav > .left > button {
    margin-right: 10px;
  }

  .intro,
  .intro2,
  .coin,
  .heart,
  .joys {
    min-height: 110vh;
  }

  .intro2 {
    min-height: 120vh;
  }

  .intro > div,
  .intro2 > div:nth-of-type(1),
  .coin > div,
  .heart > div,
  .joys > div {
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    margin: 0 5%;
    row-gap: 150px;
  }

  .img > img:not(.rot),
  .img > div > img:not(.rot) {
    width: max-content;
    max-width: 85vw;
    margin: 0 auto;
    display: block;
  }

  .wrap {
    padding: 70px 0;
    overflow-x: hidden;
    max-width: 100vw;
  }

  .li > p {
    font-size: 2rem;
  }

  .rot {
    transform: translate(0%, -50%);
  }

  .rot1 {
    left: 40vw !important;
  }

  .rot2 {
    left: 28vw !important;
  }

  .rot3 {
    left: 41vw !important;
  }

  .rot4 {
    left: 40vw !important;
  }

  .rot5 {
    left: 40vw !important;
  }

  .roadmap {
    padding: 0px 0;
  }

  .team > div:first-of-type,
  .nfts > div:first-of-type,
  .nfts > div:first-of-type {
    padding-bottom: 30px;
  }

  .team > .dudes,
  .nfts > .dudes,
  .floor-maps > .dudes3 {
    grid-template-columns: 100%;
    row-gap: 50px;
  }
  .nfts .dude {
    width: 100%;
    margin: 0 auto;
    padding: 0 7%;
  }
  .nfts > .dudes1 .dude {
    width: 100%;
    margin-top: 15%;
  }
  .investors > div:last-of-type {
    grid-template-columns: repeat(2, 50%);
    margin: 100px 0;
    row-gap: 50px;
  }

  .investors > div:last-of-type > img {
    width: 60%;
    height: auto;
    margin-bottom: 30px;
  }

  .contact {
    /* margin: 200px 0; */
  }

  .prologue-dude,
  .token-claim-dude,
  .buy-land-style {
    width: 90%;
  }
  .table-div {
    padding: 0;
  }
  table th,
  table td {
    font-size: 8px;
    line-height: 10px !important;
    text-align: center !important;
    letter-spacing: 0;
  }
  table td img {
    width: 100%;
  }
  .iconLabel {
    position: relative;
    top: -5px;
  }
  .floor-dude li {
    margin: 0 2px;
    font-size: 10px;
  }
  .floor-dude ul {
    padding-left: 0;
  }
  .floor-dude img {
    width: 12%;
    margin-left: 30%;
  }
  .contact-dude,
  .floor-dude {
    padding: 40px 0;
    width: 95%;
  }
  .mt-40 {
    margin-top: -15%;
  }
  .floor-maps .dude {
    margin: 0 auto;
    padding: 0;
  }
  .child {
    margin: 10px 0;
  }
  .floor-dude div {
    margin: 0;
  }
  .floor-dude div p {
    text-align: left;
  }
  table.roundedCorners th {
    padding: 35px 2px;
  }
  .child.child-first {
    margin-bottom: 15%;
  }
  .token-claim,
  .token-staking-last {
    margin-top: 35%;
  }
  .prologue-dude,
  .token-claim-dude {
    width: 90%;
  }
  .token-claim .boldHeading,
  .token-staking-last .boldHeading {
    font-size: 1.2rem;
  }
  .token-claim .boldHeading img,
  .token-staking-last .boldHeading img {
    height: 30px;
  }
}
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-5ths {
  width: 17%;
  float: left;
  text-align: center;
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 16%;
    float: left;
    text-align: center;
  }

  .top-center {
    position: absolute;
    top: 8vh !important;
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    place-items: center;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 17%;
    float: left;
    text-align: center;
  }
  table th {
    text-align: left !important;
  }

  .top-center {
    position: absolute;
    top: 12vh !important;
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    place-items: center;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 18%;
    float: left;
  }
  table th {
    text-align: left !important;
    padding-left: 3%;
  }
  #btn-close-modal {
    margin-top: 65% !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  html {
    /*background: url(images/MobileBG.png) no-repeat 50% center #0D0921 fixed !important; 
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            background:url("images/MobileBG.png") no-repeat scroll right top #0D0921;
            -o-background-size: cover;
            -moz-background-size: cover;
            -webkit-background-size:cover;
            background-size: cover;*/
  }
  .col-sm-5ths {
    width: 16%;
    float: left;
    text-align: center;
  }
  .row-partners {
    margin-top: 15% !important;
  }
  .col-partners1,
  .col-partners2 {
    justify-content: center !important;
  }

  .floor-dude {
    width: 80%;
  }
  .mt-40 {
    margin-top: -15%;
  }
  .nfts > .dudes1 .dude {
    width: 100%;
    margin-top: 15%;
  }
  .child {
    margin: 10px 0;
  }
  .floor-dude div {
    margin: 0;
  }
  .floor-dude div p {
    text-align: left;
  }
  .floor-dude img {
    width: 12%;
    margin-left: 30%;
  }
  table th,
  table td {
    font-size: 8px;
    line-height: 10px !important;
    text-align: center !important;
    letter-spacing: 0;
  }
  table.roundedCorners th {
    padding: 35px 2px;
  }
  .child.child-first {
    margin-bottom: 15%;
  }
  top-center {
    position: absolute;
    top: 8vh !important;
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    place-items: center;
  }
  .token-claim-dude button.watch-video span {
    padding-top: 10px;
    margin-top: 8px;
  }
  .token-claim-dude button.watch-video span:after {
    display: none;
  }
  .buy-land-style,
  .token-claim-dude {
    border: none;
    background: none;
    box-shadow: none;
    margin-top: 0;
    padding-top: 0;
  }
  .buy-land-style span.right {
    float: left;
  }
  .token-claim .boldHeading,
  .token-staking-last .boldHeading {
    margin-bottom: 10px;
    margin-top: 30px;
  }
  .intro2 .right,
  .heart .right {
    text-align: left;
  }
  .refreshbtn {
    display: none;
  }
  .tabs-section .custom-select {
    width: 100%;
  }
  .nft-list {
    margin: 30px 0;
    padding: 10px 0;
  }

  .tabs-section .tabcontent {
    position: inherit;
  }
  .nft-details h1 {
    margin-bottom: 20px;
  }
  .buy-land {
    width: 100%;
    height: 60px;
  }
  .nft-list .dudes .dudes,
  .tabcontent > .dudes,
  .nft-list > .dudes,
  .checkout-box > .dudes,
  .token-stakingbox > .dudes {
    grid-template-columns: 100%;
    row-gap: 20px;
  }
  .nft-list div {
    padding: 0;
  }
  .tabcontent > .dudes {
    padding: 0 0;
  }
  .nft-list .dudes .dudes div img {
    vertical-align: middle;
    height: 20px;
  }
  .tabs-section .tab {
    left: 49%;
  }
  .nft-list > .dudes {
    grid-template-columns: 100%;
    row-gap: 10px;
  }
  .checkout-box,
  .token-stakingbox {
    border-radius: 8px;
    margin: 30px 0.5%;
  }
  .checkout-img {
    padding: 8% !important;
  }
  .checkout-content > .dudes {
    display: grid;
    grid-template-columns: 10% 78% 2%;
    width: 100%;
    padding: 0 6.5%;
  }
  .checkout-content {
    margin-top: 5%;
  }
  .backbtn {
    width: 30px;
    height: 30px;
  }
  .backbtn img {
    height: 10px;
    margin: auto;
    margin-top: 5px;
  }
  .checkout-head p {
    font-size: 16px;
    margin-top: 2px;
  }
  .line:after {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    /* right: 0; */
    bottom: 0;
    top: 10%;
    left: 8%;
    right: 8%;
    box-shadow: none;
  }
  .checkout-details {
    padding-top: 0 !important;
  }
  .checkout-img .checkout-mainimg {
    padding-bottom: 30px;
  }
  .checkout-details .token-claim-dude {
    padding: 0px 8%;
  }
  .checkout-details .buy-land-style .registerbtn,
  .checkout-details .token-claim-dude button {
    margin-bottom: 50px !important;
  }
  .checkout-details .buy-land-style {
    padding: 0 8% !important;
  }

  .mobile {
    display: block;
  }

  .btnConnect {
    display: none !important;
  }

  .force-mobile {
    display: flex;
    align-items: center;
    grid-column: span 3 / span 3;
  }

  .nav > div > div {
    border: none;
    padding: 0;
  }

  .stakingpools {
    padding-top: 10%;
  }
  .tokenstaking > .dudes,
  .stakingpools .token-stakingbox > .dudes {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
  }
  .stakingpools .token-stakingbox .dude img {
    height: 150px;
  }
}

.footer-social {
  padding-top: 60px;
}

.footer-social img {
  margin: 10px;
}

.buy-land-style button.claimBtn {
  background: rgba(168, 252, 163, 0.15) !important;
  border-radius: 6px !important;
  box-sizing: border-box;
  box-shadow: none;
  backdrop-filter: none;
  border: none !important;
  width: auto !important;
  text-align: center;
  display: block;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin: 0 !important;
  padding: 8px 10px !important;
  margin-top: 12px !important;
  color: #a8fca3;
}
button.unclaimBtn {
  margin: 0 !important;
  padding: 15px 20px !important;
  margin-top: 5px !important;
}

#token-claim .container {
  width: 50%;
  margin: 0 auto;
}

.stake-claim {
  float: left;
  color: white;
  line-height: 24px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.06);
  border: 0.4px solid rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  width: 100%;
  text-align: left;
  font-family: NeueL, Courier, monospace;
  margin: 10px auto;
}

.border-claim {
  border-bottom: 0.3px solid #616161;
  /* width: 100%; */
  /* margin: auto; */
}

label .menu {
  position: absolute;
  z-index: 100;
  width: 40px;
  height: 40px;
  transition: .5s ease-in-out;
  cursor: pointer;  
  right: 35px;
  border-radius: 4px;
    background: #0d0921;
}

label .hamburger {
  position: absolute;
    top: 19px;
    left: 7px;
    width: 26px;
    height: 2px;
    background: white;
    display: block;
    transform-origin: center;
    transition: .5s ease-in-out;
}

label .hamburger:after, label .hamburger:before {
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: white;
}

label .hamburger:before { top: -10px; }

label .hamburger:after { bottom: -10px; }

label input { visibility: hidden; }

label input:checked + .menu {
  box-shadow: 0 0 0 100vw #0d0921, 0 0 0 100vh #0d0921;
  border-radius: 0;
  
}

label input:checked + .menu .hamburger {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  
}

label input:checked + .menu .hamburger:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  bottom: 0;
  
}

label input:checked + .menu .hamburger:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 0;
  
}

label input:checked + .menu + ul { opacity: 1; }

label ul {
  width: 100%;
    z-index: 200;
    position: absolute;
    top: 350px; /* Viji css top: 450px; */
    left: 45%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: .25s 0s ease-in-out;
    list-style: none;
}

label ul li {
  padding: 10px 20px;/* Viji css padding: 20px; */
  text-align: center;
  }

label a {
  font-size: 20px;
  display: block;
  text-decoration: none;
  
}

label ul li .connectBtn {
  display: flex;
  justify-content: space-evenly;
}









  /* *****************************By Poulami************************************ */


  @media all and (min-width:1024px) and (max-width: 1024px) {
    /* put your css styles in here */

    #show_bg_2 .right {
      width: auto !important;
     
     }

     #show_bg_2 .box {
      
      width: 414px  !important;
      
     }
  }
  

@media all and (min-width:960px) and (max-width: 1023px) {
  /* put your css styles in here */
}


@media all and (min-width:913px) and (max-width: 959px) {
  /* put your css styles in here */
}




@media all and (min-width:912px) and (max-width: 912px) {
  /* put your css styles in here */

      #show_bg_2, #show_bg_m {
      
          width: 90% !important;
      }

      #show_bg_2 {

        min-height: 900px !important;
        display:list-item !important;
        
      }
}


@media all and (min-width:821px) and (max-width: 911px) {
  /* put your css styles in here */

  #show_bg_2, #show_bg_m {
      
    width: 90% !important;
}

#show_bg_2 {

  min-height: 900px !important;
  display:list-item !important;
  
}  

}

@media all and (min-width:815px) and (max-width: 820px) {
  /* put your css styles in here */

  #show_bg_2, #show_bg_m {
 
    width: 90% !important;
}

#show_bg_2 {

  min-height: 900px !important;
  display:list-item !important;
  
}

}

@media all and (min-width:801px) and (max-width: 815px) {
  /* put your css styles in here */

  #show_bg_2, #show_bg_m {
      
    width: 90% !important;
}

#show_bg_2 {

  min-height: 900px !important;
  display:list-item !important;
  
}

}

@media all and (min-width:769px) and (max-width: 800px) {
  /* put your css styles in here */

  #show_bg_2, #show_bg_m {
      
    width: 90% !important;
}

#show_bg_2 {

  min-height: 900px !important;
  display:list-item !important;
  
}
}


 
@media all and (min-width:768px) and (max-width: 768px) {
  /* put your css styles in here */

  #show_bg_2, #show_bg_m {
 
    width: 90% !important;
}

#show_bg_2 {

  min-height: 900px !important;
  display:list-item !important;
  
}
}

@media all and (min-width:569px) and (max-width: 767px) {
  /* put your css styles in here */
}

@media all and (min-width:481px) and (max-width: 568px) {
  /* put your css styles in here */
}

@media all and (min-width:414px) and (max-width: 480px) {
  /* put your css styles in here */

  .li>p {
    font-size: 1.4rem !important;
    margin: 0 10px !important;
   }  
   .li>div {
    
    height: 10px  !important;
    width: 10px  !important;
  }
  
}

@media all and (min-width:380px) and (max-width: 413px) {
  /* put your css styles in here */


  .li>p {
    font-size: 1.4rem !important;
    margin: 0 10px !important;
   }  
   .li>div {
    
    height: 10px  !important;
    width: 10px  !important;
  }
  
}



@media all and (min-width:375px) and (max-width: 380px) {
  /* put your css styles in here */

  .li>p {
    font-size: 1.4rem !important;
    margin: 0 10px !important;
   }  
   .li>div {
    
    height: 10px  !important;
    width: 10px  !important;
  }

  

    .metamall-collapse-menu-item div {
     
      gap: 2px !important;
      font-size: 12px !important;
  }

  .metamall-collapse-menu-item {
    
    padding: 12px 8px !important; 
    
}
  
}
   

@media all and (min-width:321px) and (max-width: 374px) {
  /* put your css styles in here */


  .li>p {
    font-size: 1.4rem !important;
    margin: 0 10px !important;
   }  
   .li>div {
    
    height: 10px  !important;
    width: 10px  !important;
  }
}

@media all and (min-width:0px) and (max-width: 320px) {
  /* put your css styles in here */
}


/* *****************************Viji CSS Starts************************************ */


@media only screen and (min-width: 320px) and (max-width: 767px) {
  li.mobile-social {
    display: inline;
    padding-left: 50px;
  }
  /*label ul {
    top: 350px !important;
  }*/
}

/* *****************************Viji CSS Ends************************************ */

