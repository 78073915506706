/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #sidebar {
    min-width: 100%;
    max-width: 100%;
    transition: all 0.3s;
    margin-left: -100%;

    .group9227 {
      padding: 0;
      margin: 0 auto;
    }
  }

  .header {
    position: relative;
  }
  .portalSideNav svg {
    font-size: 25px;
    position: absolute;
    right: -3.5em;
    color: #62556d;
    cursor: pointer;
  }
  .app-layout-wrapper {
    padding: 20px;
  }
  .header .headerpart {
    padding-left: 20px;
  }

  .header .headerpart .namewrapper .header-text {
    font-size: 22px;
    line-height: 20px;
  }
  .btn-toggleSidebar {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  .header .headerpart {
    margin-top: 15px;
    margin-bottom: 15px;

    & .description {
      margin-top: 5px;
      font-size: 15px;
    }
  }

  .land-center-wrapper.mystorePage {
    padding: 8px;
    .blkInventory {
      padding: 0 5px 0 25px;
    }
  }
  .updateMyStore {
    .size-box .col-auto:first-child,
    .size-box .col-auto:last-child {
      width: 40%;
    }

    .blkLandUpload {
      display: block;
      text-align: center;

      .mall-blkLandSelect {
        vertical-align: middle;
        display: inherit;
      }

      .button-wrapper {
        justify-content: center;
        padding: 10px 0;
      }
    }
    .mall-container img {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .udpateStore-sidebox {
      .timeline:before {
        left: 53px;
        top: 80px;
        height: 55%;
      }
      .direction-r {
        text-align: left;
        margin: 0 25px;
        .flag {
          background: transparent;
        }
        .flag::before,
        .flag::after {
          display: none;
        }
      }
    }
  }
  .model-landSelect .header-text-wrapper {
    margin: auto;
    .productCard {
      display: table-cell;
    }
  }

  .experience-wrapper .mall-container .mall-balance,
  .experience-wrapper .mall-container .button-wrapper {
    width: 100% !important;
  }

  .experience-wrapper .mall-container .button-wrapper {
    justify-content: center !important;
  }

  .mall-container.download-exe {
    display: grid !important;

    .mall-balance {
      display: contents !important;
    }

    img {
      margin: 0 auto;
    }
  }

  .experience-wrapper.create-accout-screen {
    padding: 20px !important;
  }

  .centerpage {
    .ckpt-logo {
      width: 60%;
    }
    .mall-container.social-media-wrapper {
      width: 80%;
      height: 260px;
      margin-top: 35vh;
    }
  }

  .land-owned {
    padding: 5px;

    .header-title.land-owned-title {
      width: 100%;
      display: inline-grid;
      margin: 0;
      padding: 5px 15px;

      .buy-button-style {
        width: 100%;
      }

      img {
        margin: auto;
      }
      .titleLabel {
        display: initial;
        text-align: center;
      }
    }
    .productCard {
      width: 100%;
    }
  }

  .app-layout-wrapper {
    .mall-container {
      display: inline-block;
      text-align: center;
      width: 100%;
      .mall-balance {
        margin: 10px auto;
        width: 100%;
      }

      .button-wrapper {
        width: 100%;
        justify-content: center;
      }
    }
  }

  .dashboard-content-wrapper {
    .header-title {
      display: contents;
      font-size: 14px;

      .titleLabel {
        display: contents;

        .bold {
          margin-right: 7px;
        }
      }
    }
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .btn-toggleSidebar,
  .portalSideNav svg {
    display: none;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .btn-toggleSidebar,
  .portalSideNav svg {
    display: none;
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
  .btn-toggleSidebar,
  .portalSideNav svg {
    display: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .btn-toggleSidebar,
  .portalSideNav svg {
    display: none;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}
