.blkCreateNFT {
  border: 1px solid #4a4759;
  padding: 0 25px;
  border-radius: 5px;
  width: 75%;
  height: fit-content;
  margin-bottom: 100px;

  & img {
    max-width: 140px;
    border: 1px solid #4a4759;
    margin: 15px 0;
    border-radius: 5px;
  }

  & .clearImgSelection {
    cursor: pointer;
  }

  & button {
    margin: 15px 0;
  }

  .proceed-button {
    height: 40px;
    width: 100px;
  }
}