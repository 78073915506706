.view-all-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 28px;
  width: 100%;
  .view-all {
    border-radius: 29.13px !important;
    font-size: 10px !important;
    font-family: "Neue Machina bold";
    height: 28px;
    float: right;
    letter-spacing: 0.8px;
    margin-bottom: 20px;
  }
}
