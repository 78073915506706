$white: white;

.productCard {
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 8px;
  padding: 12px 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 17%;
  box-sizing: border-box;
  margin: 18px;
  // &.productCard-animi {
  //   transition: all .2s ease-in-out;

  //   &:hover {
  //     transform: scale(1.1);
  //   }
  // }
  .nft-wrapper {
    min-height: 18rem;
    // max-height: 18rem;
    .image-wrapper {
      margin: auto;
    }
    width: 100%;
  }
  .image4 {
    align-self: stretch;
    margin-bottom: 16px;
    object-fit: cover;
    //margin-left: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    min-width: 50%;
    max-width: 100%;
    border-radius: 6px;
  }
  .details {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    .click-text {
      //margin-top: 8px;
      .click {
        font-size: 10px;
        font-family: "Neue Machina regular";
        color: #9f9da7;
        margin-left: 5px;
        padding: 5px;
        display: block;
        text-align: center;
        margin: 15px auto;
      }
    }
  }

  .detailsForTemplate {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    .click-text {
      //margin-top: 8px;
      .click {
        font-size: 10px;
        font-family: "Neue Machina regular";
        color: #9f9da7;
        margin-left: 5px;
        padding: 5px;
        display: block;
        text-align: center;
        margin: 15px auto;
      }
    }
  }

  .namePrice {
    margin-top: 5px;
    display: flex;
    align-items: center;
    align-self: stretch;
  }
  .cube {
    font-family: "Neue Machina bold";
    font-size: 15px;
    //font-weight: 800;
    line-height: 20px;
    color: $white;
    flex: 1;
    margin-right: 10px;
    margin-left: 5px;
  }
  .frame9128 {
    padding: 2px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image12 {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  .num498000 {
    font-family: "Neue Machina regular";
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    color: $white;
  }
  .landPiece {
    width: 194px;
    font-family: "Neue Machina regular";
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: rgba(186, 186, 186, 1);
    flex: 1;
  }
  .tags {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .itemTag {
    margin-right: 8px;
  }
  .itemTagTwo {
    width: 38px;
    align-self: stretch;
  }
}

.productCard:hover {
  cursor: pointer;
}

.itemTag {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  padding-right: 6px;
  padding-left: 6px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  .num10X10 {
    font-family: "Neue Machina regular";
    font-size: 10px;
    font-weight: 300;
    line-height: 10px;
    color: $white;
    opacity: 0.8;
    letter-spacing: 0.2px;
  }
}

.new-list-wrapper {
  display: flex;
  align-content: flex-start;
  margin: 0 auto;
  width: 100%;
  margin-top: 51px;
  .productCard {
    margin-left: 20px;
  }
  .productCard:first-child {
    margin-left: 0px;
  }
}

.button-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  .sell-button {
    background: rgba(236, 156, 227, 0.17) !important;
    border: 0.01em solid rgba(255, 255, 255, 0.2) !important;
    box-shadow: 0px 0px 20.0784px rgba(196, 70, 183, 0.3) !important;
    backdrop-filter: blur(9.83px) !important;
  }
  .btn-primary {
    font-size: 10px;
    //width: 44%;
  }
  .card-button {
    font-size: 10px;
    width: 44%;
    font-family: "Neue Machina bold";
    letter-spacing: 0.5px;
  }
}

.nft-cards {
  width: 100%;
}
