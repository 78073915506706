$white: #ffff;

.header {
  display: flex;
  width: 100%;
  background-color: #120e25;
  border-bottom: 0.01em solid rgba(255, 255, 255, 0.2) !important;
  .headerpart {
    width: 80%;
    margin-top: 48px;
    margin-bottom: 48px;
    padding-left: 48px;
    .namewrapper {
      display: flex;
      .header-text {
        font-family: "Neue Machina bold";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 31px;
        color: $white;
        margin-left: 14px;
      }
      .dashboard-logo {
        width: 24px;
        height: 24px;
        //margin-top: 7px;
      }
    }

    .description {
      font-family: "Neue Machina regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      margin-top: 24px;
      color: #9f9da7;
    }
  }

  .notofication {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    .notification-wrapper {
      background: rgba(236, 156, 227, 0.18);
      border: 0.273796px solid $white;
      box-shadow: 0px 0px 8px rgba(196, 70, 183, 0.64);
      backdrop-filter: blur(9.83px);
      width: 61px;
      height: 34px;
      border-radius: 39.13px;
      display: flex;
      justify-content: center;
      align-items: center;
      .bell-icon {
        width: 10px;
        height: 10px;
        color: $white;
      }
      .no-of-notification {
        font-family: "Neue Machina regular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-align: right;
        color: $white;
        margin-left: 10px;
      }
    }
  }
}
